@import '../include.scss';

.pages-auth-public-invitation-success {
  > .container {
    padding: 24px;
    gap: 32px;
    width: 100%;
    background: $white;

    > .container-inner {
      width: 100%;
      gap: 32px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      > .test-title {
        padding: 8px;
        border-radius: 8px;
        background-color: $grey50;
        width: 100%;
        text-align: center;

        h2 {
          font-weight: 700;
        }
      }

      > .header {
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        > .header-title {
          // font-size: 28px;
          font-weight: bold;
          color: $grey900;
        }

        > .header-description {
          // font-size: 16px;
          line-height: 23.2px;
          color: $grey900;
        }
      }

      > .form {
        > .form-wrap {
          width: 100%;
          &:not(:last-child) {
            margin-bottom: 16px;
          }
          &.flex {
            display: flex;
            align-items: center;
            gap: 16px;

            > * {
              flex: 5;
              &:not(:last-child) {
                flex: 5;
              }
            }
          }

          > .form-input {
            width: 100%;
          }
          > .form-email {
            width: 100%;
            margin-bottom: 32px;
          }
        }

        > .form-submit {
          width: 100%;
          height: 56px;
          border-radius: 32px;
        }
        > .form-email {
          width: 100%;
          margin-bottom: 32px;
        }
      }
      > .form-submit {
        width: 100%;
        height: 56px;
        border-radius: 32px;
      }
    }
  }
}

.pages-auth-take-invitation-accept {
  > .container {
    padding: 24px;
    width: 100%;
    background-color: $white;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;

      > .header {
        gap: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        > .header-title {
          font-weight: 700;
          color: $grey900;
        }
        > .header-description {
          color: $grey900;
          line-height: 23.2px;
        }
      }

      > .form {
        display: flex;
        flex-direction: column;
        width: 100%;

        > .input {
          width: 100%;
          height: 56px;
        }

        > .form-first-name {
          margin-bottom: 32px;
        }

        > .form-last-name {
          margin-bottom: 32px;
        }

        > .form-checkbox {
          margin-bottom: 32px;
          > .form-checkbox-text {
            word-break: keep-all;
            word-wrap: break-word;
            margin-left: 5px;
            > a {
              color: $primary_color;
              text-decoration: none;

              &:hover {
                color: rgba($primary_color, 0.6);
              }

              &:active {
                color: rgba($primary_color, 0.8);
              }
            }
          }
        }

        > .form-submit {
          width: 100%;
          height: 56px;
          border-radius: 32px;
        }
      }
    }
  }
}

.pages-auth-take-invitation-invalid {
  > .container {
    width: 540px;
    padding: 40px;
    background-color: white;

    > .header {
      > .header-title {
        // font-size: 28px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      > .header-img {
        width: 160px;
        height: 160px;
        background-image: url('/assets/images/icon/testtaker/img-invalid-invitation.svg');
        margin: 0 auto;
      }
      > .header-description {
        color: $grey900;
        line-height: 23.2px;
        > .center {
          margin: 16px auto 0;
        }
      }
      > .flex {
        display: flex;
      }
    }

    > .form {
      display: flex;
      flex-direction: column;

      > .input {
        width: 100%;
        height: 56px;
      }

      > .form-first-name {
        margin-bottom: 32px;
      }

      > .form-last-name {
        margin-bottom: 32px;
      }

      > .form-checkbox {
        margin-bottom: 32px;

        > a {
          color: $primary_color;
          text-decoration: none;

          &:hover {
            color: rgba($primary_color, 0.6);
          }

          &:active {
            color: rgba($primary_color, 0.8);
          }
        }
      }

      > .form-submit {
        width: 100%;
        height: 56px;
        border-radius: 32px;
      }
    }
  }
}

.pages-auth-take-invitation-preparing {
  > .container {
    width: 540px;
    padding: 40px;
    background-color: white;

    > .header {
      > .header-title {
        // font-size: 28px;
        font-weight: bold;
        margin-bottom: 8px;
      }
      > .header-img {
        width: 160px;
        height: 160px;
        background-image: url('/assets/images/icon/testtaker/img-preparing-invitation.svg');
        margin: 0 auto;
      }
      > .header-description {
        color: $grey900;
        line-height: 23.2px;
        > .center {
          margin: 16px auto 0;
        }
      }
      > .flex {
        display: flex;
      }
    }

    > .form {
      display: flex;
      flex-direction: column;

      > .input {
        width: 100%;
        height: 56px;
      }

      > .form-first-name {
        margin-bottom: 32px;
      }

      > .form-last-name {
        margin-bottom: 32px;
      }

      > .form-checkbox {
        margin-bottom: 32px;

        > a {
          color: $primary_color;
          text-decoration: none;

          &:hover {
            color: rgba($primary_color, 0.6);
          }

          &:active {
            color: rgba($primary_color, 0.8);
          }
        }
      }

      > .form-submit {
        width: 100%;
        height: 56px;
        border-radius: 32px;
      }
    }
  }
}

.pages-public-tips {
  .code-alert,
  .refresh-alert {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 8px;
    width: 100%;
    background: $red50;
    border-radius: 8px;

    > .desc {
      width: 100%;
      line-height: 1.3;
      font-style: normal;
      font-weight: 700;
      display: flex;
      align-items: center;
      letter-spacing: -0.01em;
      color: $black;
      word-break: keep-all;
      word-wrap: break-word;
    }
  }

  .code-alert {
    margin-top: 8px;
  }
  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 10px;
    width: 100%;
    margin-top: 32px;
    background: $white;
    border-radius: 8px;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
      width: 100%;
      > .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        > .header-title {
          // font-size: 28px;
          font-weight: bold;
        }

        > .header-description {
          word-break: keep-all;
          word-wrap: break-word;
          line-height: 23.2px;
        }
      }

      > .alert {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        > .alert-title {
          // font-size: 20px;
          font-weight: bold;
        }

        > ul.alert-list {
          > li.alert-list-item {
            position: relative;
            padding-left: 10px;
            line-height: 23.2px;
            word-break: keep-all;
            word-wrap: break-word;

            &:not(&:last-child) {
              margin-bottom: 4px;
            }

            &::before {
              content: '';
              position: absolute;
              top: 8px;
              left: 0px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $grey900;
            }
          }
        }
      }

      > .submit {
        width: 100%;
        height: 56px;
        border-radius: 32px;
        // font-size: 16px;
      }
    }
  }
}

.pages-public-intro {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    max-width: 90%;
    width: 100%;
    border-radius: 8px;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
      width: 100%;

      > .video-box {
        #intro-video {
          display: none;
          width: 100%;
        }
      }

      > .submit {
        max-width: 460px;
        width: 100%;
        height: 56px;
        border-radius: 32px;
        margin: 0 auto;
      }
    }
  }
}

.landing-container {
  z-index: 15;
  background-color: $grey100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  transition: top 1s ease-in-out;

  &.move-up {
    top: -100%;
  }

  .landing-video {
    width: auto;
    min-width: 1460px;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 120%;
    transform: translate(-50%, -50%);
  }

  .landing-text-container {
    z-index: 10;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .welcom-text-box {
      svg {
        width: 80%;
      }
    }

    .landing-next-button {
      position: absolute;
      bottom: 50px;

      svg {
        width: 100px;
      }
    }
  }

  .landing-loading {
    display: flex;
    flex-direction: column;
    gap: 12px;

    & > :first-child {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 100% */
      letter-spacing: -0.28px;
      color: $grey900;
    }

    & > :nth-child(2) {
      color: $grey600;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px; /* 200% */
      letter-spacing: -0.14px;
    }

    & > :nth-child(3) {
      height: 30px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 11px;
      > span {
        background-color: black;
        border-radius: 82.609px;
        width: 12px;
        height: 16px;
        animation: growShrink 1s alternate cubic-bezier(0.11, 0.71, 0.86, 0.43)
          infinite;

        &:nth-child(1) {
          animation-delay: 0.33s;
        }
        &:nth-child(2) {
          animation-delay: 0.66s;
        }
        &:nth-child(3) {
          animation-delay: 0.99s;
        }
      }
      @keyframes growShrink {
        0% {
          height: 16px;
        }
        25% {
          height: 18px;
        }
        50% {
          height: 20px;
        }
        75% {
          height: 23px;
        }
        100% {
          height: 26px;
        }
      }
    }
  }
}

.relative {
  position: relative;
  width: 100%;
  height: 100%;
}

.demo-auth-container {
  display: flex;
  height: calc(100vh - 56px - 132px);

  &.personality {
    .left-container {
      display: none;
    }
  }

  .left-container {
    display: none;
  }

  .right-container {
    flex: 4.5;
    display: flex;
    justify-content: center;
    align-items: center;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 400px;
      padding: 0 24px;

      .assessment-name-box {
        color: $grey900;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        margin-bottom: 54px;
        word-break: keep-all;
      }

      .demo-form-input-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
        margin-bottom: 20px;

        &.public-invitation {
          width: 100%;
          flex-wrap: wrap;
        }
        .public-invitation-from-input {
          width: 100%;
          margin: 0;
          svg {
            path {
              fill: gray;
            }
          }
        }

        .demo-form-input {
          flex: 1;
        }
      }

      .policy-check-container {
        color: $grey900;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%;
        letter-spacing: -0.14px;
        margin-bottom: 54px;

        .form-checkbox {
          display: inline-flex;
          align-items: center;

          &::before {
            content: '';
            display: inline-flex;
            justify-content: center;
            align-items: center;
            width: 18px;
            height: 18px;
            border: 1px solid $grey900;
            margin-right: 4px;
            background-position: center;
          }

          &.checked::before {
            background-image: url('../../assets/images/icon/demo-checkbox.svg') !important;
          }

          > div {
            width: calc(100% - 22px);

            * {
              line-height: 100%;
            }
          }
        }
      }

      .demo-form-submit {
        width: 100%;
        padding: 20px 10px;
        border-radius: 50px;
        cursor: default;
        background-color: $grey300;
        color: $grey400;
        text-align: center;
        margin: 0 auto;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 75%;
        letter-spacing: -0.32px;

        &.active {
          cursor: pointer;
          background-color: $grey900;
          color: white;

          &:hover {
            background-color: rgba($grey900, 0.6);
          }

          &:active {
            background-color: rgba($grey900, 0.8);
          }
        }
      }
    }
  }
}

.demo-tips-container {
  padding: 40px 24px;
  min-height: calc(100vh - 56px - 132px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  max-width: 400px;
  margin: 0 auto;

  @media #{$w448px} {
    gap: 24px;

    .demo-tips-down-container {
      .align-right {
        > :nth-child(2) {
          margin-bottom: 16px !important;
        }
      }
    }
  }

  .demo-tips-above-container {
    // margin-bottom: 4.083vw;
    > .candidate-name {
      color: $grey900;
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -2px;
      margin-bottom: 1.563vw;
    }

    > .Greeting {
      color: $grey800;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.24px;
    }
  }

  .demo-tips-down-container {
    display: flex;

    .align-right {
      > :first-child {
        color: $grey900;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.2px;
        margin-bottom: 12px;
      }

      > :nth-child(2) {
        color: $grey800;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.14px;
        margin-bottom: 32px;

        ul {
          > li {
            position: relative;
            padding-left: 10px;
            line-height: 24px;

            &::after {
              content: '';
              display: block;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background-color: $grey900;
              position: absolute;
              top: 10px;
              left: 0px;
            }
          }
        }
      }
      > :nth-child(3) {
        color: $grey800;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.12px;
      }
    }
  }

  .code-alert {
    display: flex;
    gap: 12px;
    padding: 20px 16px;
    border-radius: 10px;
    background-color: $grey100;
    box-shadow: 2px 12px 30px 0px rgba(0, 0, 0, 0.03);

    svg {
      width: 24px;
      height: 24px;
    }

    span.desc {
      width: calc(100% - 34px);
      color: $grey900;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: 0.12px;
      word-break: keep-all;
    }
  }

  .start-button-container {
    display: flex;
    justify-content: flex-end;

    .start-button {
      color: $white;
      width: 96px;
      padding: 8px 16px;
      border-radius: 32px;
      font-size: 16px;
      font-style: normal;
      white-space: nowrap;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: -0.16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      cursor: pointer;
      transition: all 0.25s;
      background-color: $grey900;

      &.disabled {
        background-color: $grey300;
      }

      &:hover {
        // letter-spacing: 4px;
      }
    }
  }
}
