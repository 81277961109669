@import '../include.scss';

$pcSmall: 'only screen and (max-width: 1440px)';

/* for highlight */
pre {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 150% !important;
  word-break: break-all;
  white-space: pre-wrap;
  &:not(&:last-child) {
    margin-bottom: 16px;
  }
}
code.hljs {
  padding: 24px !important;
}
.hljs {
  background: $grey900 !important;
  border-radius: 8px !important;
}

.codeBlock {
  width: 100%;
  > p {
    font-size: 18px;
    font-weight: bold;
    line-height: 23.2px;
    color: $grey900;
    margin-bottom: 8px;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
.codeBlock-sub {
  width: 100%;
  max-width: 410px;
}

.pages-public-setup {
  > .container-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 32px;
    width: 1040px;
    background: $white;
    border-radius: 8px;
  }

  .container {
    display: flex;
    gap: 40px;

    > .container-left {
      flex: 1;

      > .header {
        margin-bottom: 32px;

        > .header-title {
          // font-size: 28px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        > .header-description {
          line-height: 23.2px;
          color: $grey900;
        }
      }

      > .camera,
      > .mic {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(&:last-child) {
          margin-bottom: 32px;
        }

        > .camera-left,
        > .mic-left {
          width: 100%;

          > .camera-left-title,
          > .mic-left-title {
            font-weight: bold;
            margin-bottom: 8.5px;
          }

          .mic-canvas {
            width: 100%;
            height: 4px;
            background: #e2e6e9;
            border-radius: 4px;
          }

          > .camera-left-selected,
          > .mic-left-selected {
            color: $grey900;
          }
        }

        > .camera-right,
        > .mic-right {
          position: relative;

          > .camera-right-setting,
          > .mic-right-setting {
            width: 56px;
            height: 56px;
            border-radius: 50%;

            &::after {
              content: url('/assets/images/icon/setting.svg');
            }
          }

          > .camera-right-dropdown,
          > .mic-right-dropdown {
            position: absolute;
            top: 61px;
            right: 28px;
            z-index: 10;

            > ul {
              padding: 8px 0;
              box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
                0px 1px 2px rgba(0, 0, 0, 0.3);
              border-radius: 4px;
              background-color: white;

              > li {
                cursor: pointer;
                padding: 0 16px;
                width: 270px;
                height: 48px;
                display: inline-flex;
                align-items: center;

                &:hover {
                  background-color: $grey100;
                }
              }
            }
          }
        }
      }
    }

    > .container-right {
      flex: 1;

      > .video {
        background-color: $grey100;
        width: 100%;
        height: 345px;
        margin-bottom: 32px;
        transform: rotateY(180deg);
        overflow: hidden;
        border-radius: 8px;

        > .video-display {
          width: 100%;
          height: 100%;
        }
      }

      > .alert {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 32px;
        width: 460px;
        background: $grey50;
        > .alert-title {
          // font-size: 18px;
          font-weight: 500;
        }

        > ul.alert-list {
          > li.alert-list-item {
            position: relative;
            padding-left: 20px;
            line-height: 23.2px;
            word-break: keep-all;
            word-wrap: break-word;

            &:not(&:last-child) {
              margin-bottom: 4px;
            }

            &::before {
              content: '';
              position: absolute;
              top: 9px;
              left: 8px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $grey900;
            }
          }
        }
      }
    }
  }

  .submit {
    width: 460px;
    height: 56px;
    border-radius: 32px;
  }
}

.pages-public-voice-test {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 32px;
    width: 1040px;
    background: $white;
    border-radius: 8px;
  }

  .container-wrapper {
    display: flex;
    gap: 40px;
    width: 100%;

    > .container-left {
      flex: 1;

      > .header {
        margin-bottom: 32px;

        > .header-title {
          font-weight: bold;
          margin-bottom: 8px;
        }

        > .header-description {
          line-height: 23.2px;
          color: $grey900;
        }
      }

      > .read-sentence {
        position: relative;

        > .read-sentence-wrapper {
          padding: 24px;
          background-color: $grey50;
          border-radius: 16px;
          border: 1px solid $grey200;
          text-align: center;

          p {
            font-weight: 700;
            background-image: linear-gradient(
              90deg,
              #73b7f5 4.98%,
              #0066ff 33.94%,
              #bcd7ff 96%
            );
            -webkit-background-clip: text;
            background-clip: text;
            -webkit-text-fill-color: transparent;
            // text-fill-color: transparent;
            background-size: 300% auto;
            animation: text-gradient 4s ease-in-out infinite;
            -webkit-animation: text-gradient 4s ease-in-out infinite alternate;
          }

          @keyframes text-gradient {
            0% {
              background-position: 0% 50%;
            }
            100% {
              background-position: 100% 50%;
            }
          }
        }
      }
    }

    > .container-right {
      flex: 1;

      > .video-stream-contents {
        width: 100%;

        .video-box {
          background-color: $black;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0;
          margin-bottom: 32px;
          position: relative;
          border-radius: 8px;
          overflow: hidden;

          .video {
            width: auto;
            max-width: 100%;
            height: 260px;
            transform: rotateY(180deg);
          }

          .face-canvas {
            position: absolute;
            top: 0;
            left: 50%;
            transform: rotateY(180deg) translateX(50%);
          }

          .record-time {
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            width: 100%;
            bottom: 0;
            left: 0;
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: center;
            z-index: 1;
            padding: 4px 0;

            * {
              color: $white;
              fill: $white;
              font-weight: 700;
            }
          }

          .video-bg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
            z-index: 2;

            .video-bg-rect {
              top: 24px;
              left: calc(50% - 112px);
              width: 220px;
              height: 178px;
              position: absolute;
              border: 1px solid $white;
              border-radius: 8px;

              &::after {
                top: -2px;
                left: -2px;
                content: '';
                position: absolute;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                border-radius: 8px;
                box-shadow: 0 0 0 999px rgba($black, 0.2);
              }
            }
          }
        }

        .button-wrapper {
          margin-bottom: 32px;

          .submit {
            font-weight: 500;
            &.disabled {
              background-color: rgba($primary_color, 0.6);
              cursor: default;
            }
          }

          .loading {
            background-color: $grey100;
            color: $grey900;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 24px;
            font-weight: 500;
          }
        }

        .media-setting-alert {
          padding: 16px;
          background-color: $grey50;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 16px;

          * {
            font-weight: 500;
          }

          .media-setting-button {
            color: $blue500;
            text-align: right;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  .submit {
    width: 460px;
    height: 56px;
    border-radius: 32px;
  }
}

.pages-public-selecttest {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 10px;
    width: 1040px;
    background: $white;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
      width: 100%;
      > .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        > .header-title {
          font-weight: 800;
          color: $grey900;
        }

        > .header-description {
          color: $grey900;
          line-height: 23.2px;
          > span {
            color: $blue500;
          }
        }
      }
      > .test-select {
        padding: 0px;
        gap: 32px;
        width: 100%;
        > ul.test-list {
          display: grid;
          grid-template-columns: repeat(auto-fill, 460px);
          grid-gap: 16px;
          justify-content: space-around;

          > li.test-list-item {
            border: 1px solid $grey100;
            border-radius: 4px;

            &:hover {
              background: $grey50;
            }

            > .container {
              display: flex;
              align-items: center;
              width: 460px;
              padding: 4px;
              border-radius: 4px;
              > p {
                // word-break: break-all;
                word-break: keep-all;
                word-wrap: break-word;
              }
            }

            &.selected {
              > .container {
                background-color: $primary_color;

                > .radio-button {
                  svg {
                    color: white;
                    > path {
                    }
                  }
                }

                > p {
                  color: white;
                }
              }
            }
          }
        }
      }
      > .submit {
        margin-top: 64px;
        > button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 18px 24px 19px;
          gap: 8px;
          width: 960px;
          height: 60px;
          border-radius: 32px;
          &::after {
            width: 12px;
            height: 12px;
            display: inline-block;
            content: '';
            -webkit-mask: url('/assets/images/icon/arrow-right-white.svg')
              no-repeat 50% 50%;
            mask: url('/assets/images/icon/arrow-right-white.svg') no-repeat 50%
              50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-color: $grey500;
          }
          &.active::after {
            background-color: $white;
          }
        }
      }
    }
  }
}

.pages-protected-evaluation-test-start {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 40px;
    width: 540px;
    background: $white;
    border-radius: 8px;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
      width: 100%;

      > .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        > .title-sub {
        }

        > .title-main {
          // font-size: 28px;
          font-weight: bold;
        }
      }

      > .desc {
        padding-bottom: 32px;
        border-bottom: 1px solid $grey300;
        display: flex;
        flex-direction: column;
        gap: 32px;

        > p {
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
        }

        > .video-desc {
          .video-desc-grey-box {
            padding: 16px;
            background-color: $grey50;
            border-radius: 8px;

            .video-desc-title {
              font-weight: 700;
              margin-bottom: 16px;
            }

            .video-desc-list {
              list-style: auto;
              padding-left: 16px;
            }
          }
        }
      }

      > .alert {
        > p {
          // font-size: 18px;
          font-weight: bold;

          > span {
            color: $primary_color;
          }
        }
      }

      > button.skip {
        padding: 0 16px;
        height: 39px;
        border-radius: 100px;

        &::after {
          content: url('/assets/images/icon/arrow-right-white.svg');
          margin-left: 11px;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

.pages-protected-evaluation-language-test {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 40px;
    width: 540px;
    background: $white;
    border-radius: 8px;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
      width: 100%;

      > .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;

        > .title-main {
          font-weight: bold;
        }
      }

      > .select-container {
        width: 100%;

        .select-wrapper {
          fieldset {
            width: 100%;
          }

          .check-button {
            padding: 16px;
            margin: 0;
            border: 1px solid $grey100;
            transition: all 0.1s;
            font-size: 16px;
            font-weight: 500;
            border-radius: 4px;
            overflow: hidden;

            .MuiCheckbox-root {
              padding: 0;
              padding-right: 16px;
            }

            &.selected {
              border: 1px solid $blue500;

              .select-tag {
                background-color: $red500;
                color: $white;
              }

              .Mui-disabled {
                .MuiSvgIcon-root {
                  fill: $blue500 !important;
                }
              }
            }

            &:not(:last-child) {
              margin-bottom: 16px;
            }
            &:hover {
              background-color: $grey50;
            }

            .select-tag {
              padding: 4px 6px 2px;
              background-color: $grey100;
              border-radius: 16px;
              color: $grey500;
              font-weight: 700;
            }
          }
        }
      }

      > button.start {
        display: inline-block;
        width: 100%;
        padding: 18.5px;
        font-weight: 500;

        &::after {
          content: url('/assets/images/icon/arrow-right-white.svg');
          margin-left: 11px;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

.pages-protected-evaluation-test-end {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 10px;
    width: 540px;
    background: $white;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;

      > .title {
        // font-size: 28px;
        font-weight: bold;
        margin-bottom: 8px;
      }

      > .description {
        line-height: 23.2px;
        color: $grey900;
        margin-bottom: 32px;
      }

      > .go-next {
        width: 100%;
        height: 56px;
        border-radius: 32px;
      }

      > .video-box {
        margin-bottom: 32px;

        #intro-video {
          // display: none;
          width: 100%;
        }
      }

      > .submit {
        max-width: 460px;
        width: 100%;
        height: 56px;
        border-radius: 32px;
        margin: 0 auto;
        // font-size: 16px;
      }
    }
  }
  &.last {
    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 40px;
      width: 1040px;
      background: $white;
      > .container-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 100%;
        > .title {
          // font-size: 28px;
          font-weight: bold;
          margin-bottom: 8px;
          &.gucci {
            align-items: center;
            text-align: center;
            margin-bottom: 16px;
            width: 100%;
          }
        }

        > .sub-title {
          width: 100%;
          color: $grey900;
          margin-bottom: 32px;
          text-align: center;
          font-weight: 600;
        }

        > .experience {
          width: 100%;
          > .experience-title {
            color: $grey900;
            margin-bottom: 14px;
          }

          > .experience-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            margin-bottom: 16px;

            > .experience-buttons-button {
              cursor: pointer;
              width: 80px;
              height: 80px;
              display: inline-flex;
              justify-content: center;
              align-items: center;

              &::after {
                transition: opacity 0.1s;
              }

              &.point-1 {
                &::after {
                  content: url('/assets/images/icon/scores/point1.svg');
                  opacity: 0.2;
                }
              }

              &.point-2 {
                &::after {
                  content: url('/assets/images/icon/scores/point2.svg');
                  opacity: 0.2;
                }
              }

              &.point-3 {
                &::after {
                  content: url('/assets/images/icon/scores/point3.svg');
                  opacity: 0.2;
                }
              }

              &.point-4 {
                &::after {
                  content: url('/assets/images/icon/scores/point4.svg');
                  opacity: 0.2;
                }
              }

              &.point-5 {
                &::after {
                  content: url('/assets/images/icon/scores/point5.svg');
                  opacity: 0.2;
                }
              }

              &:hover {
                &::after {
                  opacity: 0.6;
                }
              }

              &.selected {
                &::after {
                  opacity: 1;
                }
              }
            }

            &.selected {
              > .experience-buttons-button {
                filter: grayscale(1);

                &.selected {
                  filter: none;
                }
              }
            }
          }

          > .experience-text-field {
            margin-bottom: 32px;
          }
        }

        > .accuracy {
          width: 100%;
          margin-bottom: 32px;

          > .accuracy-title {
            // font-size: 18px;
            font-weight: bold;
            margin-bottom: 32px;
          }

          > .accuracy-tests {
            > ul.accuracy-tests-list {
              > li.accuracy-tests-list-item {
                > .container {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  > .skill-title {
                    max-width: 50%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                  }

                  > .buttons {
                    > button {
                      padding: 0 24px;
                      height: 40px;
                      border: 1px solid $grey300;
                      background-color: transparent;
                      transition: background-color 0.1s;

                      &:hover {
                        background-color: $grey300;
                      }

                      &.selected {
                        background-color: $primary_color;
                        border: 1px solid transparent;
                        color: white;
                      }
                    }
                  }
                }

                &:not(&:last-child) {
                  margin-bottom: 32px;
                }
              }
            }
          }
        }

        > .survey {
          margin-bottom: 32px;
          width: 100%;

          > .survey-description {
            line-height: 23.2px;
            color: $grey900;
            padding: 24px;
            background-color: $grey50;
            border-radius: 16px;
            margin-bottom: 32px;
            word-break: keep-all;
            word-wrap: break-word;
          }

          > .survey-inputs-check {
            margin-bottom: 32px;

            > label.fs18 {
              color: $grey900;
              font-weight: 500;
            }

            .MuiTextField-root {
              margin: 20px 0 20px 0;
              width: 100%;
            }
          }

          > .survey-inputs {
            display: flex;
            justify-content: space-between;
            gap: 32px;

            > * {
              flex: 1;
            }

            > .survey-inputs-right {
              display: flex;
              justify-content: space-between;
              gap: 16px;
              position: relative;

              > * {
                &:nth-child(1) {
                  flex: 1;
                }

                &:nth-child(2) {
                  flex: 2;
                }
              }

              .currency-absolute {
                position: absolute;
                z-index: 10;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
                color: $grey500;
              }
            }

            &:not(&:last-child) {
              margin-bottom: 32px;
            }
          }
        }

        > button.submit {
          width: 460px;
          height: 56px;
          border-radius: 32px;
          margin: 0 auto;
        }
      }
    }
  }
}

.pages-protected-evaluation-subjectives-video {
  &.subjective {
    > .container {
      position: relative;
      > .spliter {
        > .spliter-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    width: 1040px;
    padding: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;
    position: relative;

    &.more-width {
      width: 100%;

      > .spliter {
        > .spliter-right {
          > .video {
            max-height: none;
            width: 800px;
          }
        }
      }
    }

    > .question-modal {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(8px);
      width: 100%;
      height: 100%;
      z-index: 0;
      transition: all 0.3s ease-in-out;
      border-radius: 8px;
      padding: 40px;
      text-align: center;

      &.show {
        transform: scale(1);
        z-index: 30;
      }

      &.hide {
        transform: scale(0);
      }
    }

    > .spliter {
      display: flex;
      width: 100%;
      gap: 40px;

      > .spliter-left {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(
          100vh - 120px - 99px - 80px
        ); /*전체 박스에서 상하단 padding제외*/
        overflow-y: auto;
        padding-right: 20px;

        > .question {
          line-height: 23.2px;
          margin-bottom: 24px;
          overflow-y: auto;
          word-break: keep-all;
          word-wrap: break-word;
          // background-color: $grey50;
          // padding: 20px;

          > * {
            all: revert;
            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              max-height: 500px;
              margin-top: 16px;
              width: 100%;
            }
          }

          > a {
            display: block;
            width: 100%;
            text-align: center;

            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              max-height: 500px;
              margin-top: 16px;
              height: 100%;
              width: auto;
              margin-left: auto;
              margin-right: auto;
            }
          }

          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 10px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .timer {
            margin-bottom: 36px;
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 3px;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }

        > .timer {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 32px;

          .timer-wrap {
            text-align: center;

            .timer-title {
              display: inline-block;
              font-weight: 700;
              margin-bottom: 8px;
            }

            &.inactive {
              .score-graph {
                color: $grey500;
                * {
                  stroke: $grey100;
                }
              }
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(
          100vh - 120px - 99px - 80px
        ); /*전체 박스에서 상하단 padding제외*/
        overflow-y: auto;
        // padding-left: 20px;

        > .video {
          width: 100%;
          height: 100%;
          max-height: 250px;
          background-color: $black;
          margin-bottom: 32px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          position: relative;
          border-radius: 8px;

          > .timer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1;
            display: flex;
            padding: 4px 0;
            gap: 10px;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, 0.5);
            color: $white;
            font-weight: 700;
            &.stop {
              display: none;
            }

            * {
              fill: $white;
            }
          }

          > video {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            transform: rotateY(180deg);
          }

          video::-webkit-media-controls {
            transform: rotateY(180deg) !important;
          }

          &.start {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: hidden;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-timeline {
                visibility: hidden;
              }
            }
          }

          &.stop {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: visible;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: visible;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: visible;
              }

              &::-webkit-media-controls-timeline {
                visibility: visible;
              }
            }
          }
        }

        > .alert-message {
          padding: 16px;
          background-color: $grey50;
          border-radius: 8px;
          margin-bottom: 32px;

          &.stop {
            display: none;
          }
        }

        > .buttons {
          display: flex;
          gap: 24px;
          margin-bottom: 32px;

          > button {
            flex: 1;
            height: 56px;
            border-radius: 32px;

            &:disabled {
              cursor: default;
              background-color: rgba($secondary_color, 0.6);
            }
          }
        }

        // > .timer {
        //   font-weight: bold;
        //   color: $blue500;
        //   &.stop {
        //     display: none;
        //   }

        //   margin-bottom: 32px;
        //   // font-size: 18px;

        //   &::before {
        //     content: url("/assets/images/icon/timer.svg");
        //     margin-right: 7px;
        //     position: relative;
        //     top: 3px;
        //   }
        // }

        > .record-cnt {
          &.start {
            display: none;
          }

          i {
            color: $blue500;
          }
          margin-bottom: 32px;
          font-weight: bold;
        }

        > .alert {
          background-color: $grey100;
          padding: 16px;
          border-radius: 8px;
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: space-between;

          &.demo {
            gap: 8px;
            padding: 0;
            background: white;
            justify-content: left;
          }

          &.start {
            display: none;
          }

          > .alert-title {
            // font-size: 18px;
            font-weight: 500;
          }

          > .media-setting-button {
            color: $blue500;
            font-weight: 500;
            text-align: right;

            &:hover {
              text-decoration: underline;
            }
          }

          > ul.alert-list {
            > li.alert-list-item {
              position: relative;
              padding-left: 20px;
              line-height: 23.2px;
              word-break: keep-all;
              word-wrap: break-word;

              &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 8px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $grey900;
              }
            }
          }
        }
      }
    }

    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-subjectives-essay {
  &.subjective {
    > .container {
      > .spliter {
        > .spliter-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    width: 1040px;
    padding: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    &.more-width {
      width: 100%;

      .tox-tinymce {
        height: 100% !important;
        min-height: 400px;
      }
    }

    > .spliter {
      width: 100%;
      display: flex;
      gap: 40px;
      > .spliter-left {
        flex: 1;
        width: calc(50% - 20px);
        > .question {
          max-height: calc(100vh - 120px - 99px - 80px - 33px);
          // font-weight: bold;
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
          overflow-y: auto;
          // background-color: $grey50;
          // padding: 20px;

          > * {
            all: revert;
            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              margin-top: 16px;
              width: 100%;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 10px;

            * {
              all: revert;
              margin: 0 !important;
            }

            // 테이블 테그
            .tg {
              border-collapse: collapse;
              border-spacing: 0;
              width: 100%;

              th {
                border-color: black;
                border-style: solid;
                border-width: 1px;
                font-size: 14px;
                font-weight: normal;
                overflow: hidden;
                padding: 10px 5px;
                word-break: normal;
              }

              td {
                border-color: black;
                border-style: solid;
                border-width: 1px;
                font-size: 14px;
                overflow: hidden;
                padding: 10px 5px;
                word-break: normal;
              }

              .tg-th {
                background-color: #6980c4;
                color: #ffffff;
                font-weight: bold;
                text-align: center;
                vertical-align: top;
              }

              .tg-td {
                text-align: center;
                vertical-align: top;
              }

              .tg-td-last {
                background-color: #ffcc67;
                text-align: center;
                vertical-align: top;
                text-decoration: underline;
                font-weight: bold;
              }
            }
          }

          > .video-box {
            margin-bottom: 32px;

            .question-video {
              display: block;
              width: 100%;
            }
          }
          > .timer {
            margin-bottom: 36px;
            font-weight: 700;
            color: $blue500;
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }
          > .style-image-box {
            height: auto;
            margin-top: 48px;
            padding-top: 30px;
            padding-right: 40px;

            > .style-image {
              display: block;
              width: 100%;
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: calc(50% - 20px);

        #mceu_15 {
          min-height: 160px;
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-subjectives-code {
  > .container {
    width: 1040px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    > .spliter {
      width: 100%;
      height: 100%;
      display: flex;
      > .spliter-left {
        width: 50%;
        padding: 40px;
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
        > .question {
          max-height: calc(100vh - 120px - 99px - 80px - 33px);
          // font-weight: bold;
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
          height: 100%;
          // background-color: $grey50;
          // padding: 20px;

          > pre {
            word-break: keep-all;
            word-wrap: break-word;
            color: $grey900;
            font-weight: 700;
            line-height: 1.8 !important;
            // text-indent: 10px;
          }
        }

        > .timer {
          &::before {
            content: url('/assets/images/icon/timer.svg');
            margin-right: 7px;
            position: relative;
            top: 4px;
          }
        }
      }

      > .spliter-right {
        width: 50%;
        // max-height: calc(100vh - 120px - 99px - 33px);
        height: 100%;

        > .tab {
          ul {
            display: flex;
            align-items: center;

            li {
              padding: 20px 24px;
              font-weight: 700;
              cursor: pointer;
              position: relative;
              transition: all 0.2s;

              &:hover {
                color: $blue500;
              }

              &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 0;
                height: 2px;
                background-color: $blue500;
                transition: all 0.2s;
              }

              &.active {
                color: $blue500;

                &::after {
                  width: 45px;
                }
              }
            }
          }
        }

        .editor-select {
          // height: 48px;
          padding: 8px 16px;
          background-color: $grey800;
          position: relative;
          > .question-language-select-box
            > .question-language-select-caution
            > span {
            color: $white;
          }

          > span {
            display: block;
            background-color: $grey700;
            color: $white;
            padding: 6px 8px;
            border-radius: 4px;
          }
        }

        .editor-input {
          position: relative;
          height: calc((100% - 63.2px - 56.2px) * 0.7);

          * {
            font-family: Consolas, source-code-pro, Menlo, Monaco, 'Courier New',
              monospace !important;
          }
        }

        .editor-result-button-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;
          background-color: $grey800;
          margin-top: -1px;

          > span {
            display: inline-block;
            font-weight: bold;
            color: $white;
          }
          > button {
            padding: 10.5px 12px;
            border-radius: 4px;
            display: flex;
            align-items: center;

            > svg {
              margin-left: 10px;
            }
          }
          > button[disabled] {
            background-color: $blue200;
            cursor: default;
          }
        }

        .editor-output {
          margin-top: -1px;
          position: relative;
          height: calc((100% - 63.2px - 56.2px) * 0.3);

          #iframe-preview {
            width: 100%;
            height: 100%;
          }
        }

        .output-result-box {
          background-color: $grey900;
          height: 100%;
          padding: 8px 16px;
          overflow-y: auto;

          > p,
          pre {
            color: $white;
          }
        }

        #mceu_15 {
          min-height: 160px;
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-objectives-common {
  > .container {
    width: 1040px;
    padding: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    &.more-width {
      width: 100%;
    }

    > .separater {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 40px;

      > .separater-left {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(
          100vh - 120px - 99px - 80px
        ); /*전체 박스에서 상하단 padding제외*/
        overflow-y: auto;
        padding-right: 20px;

        > .question {
          // font-weight: bold;
          line-height: 23.2px;
          color: $grey900;
          margin-bottom: 8px;
          word-break: keep-all;
          word-wrap: break-word;
          overflow-y: auto;
          // background-color: $grey50;
          // padding: 20px;

          > * {
            all: revert;
            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              margin-top: 16px;
              width: 100%;
            }
          }

          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 10px;

            * {
              all: revert;
              margin: 0 !important;
            }

            .rect-border {
              border: 1px solid $grey300;
              padding: 8px 10px;

              .center {
                padding: 20px 0;
              }
            }

            .center {
              text-align: center;
            }

            .tg {
              border-collapse: collapse;
              border-spacing: 0;
              width: 100%;

              th {
                border-color: black;
                border-style: solid;
                border-width: 1px;
                font-size: 14px;
                font-weight: normal;
                overflow: hidden;
                padding: 10px 5px;
                word-break: normal;
              }

              td {
                border-color: black;
                border-style: solid;
                border-width: 1px;
                font-size: 14px;
                overflow: hidden;
                padding: 10px 5px;
                word-break: normal;
              }

              .tg-th {
                background-color: #6980c4;
                color: #ffffff;
                font-weight: bold;
                text-align: center;
                vertical-align: top;
              }

              .tg-td {
                text-align: center;
                vertical-align: top;
              }

              .tg-td-last {
                background-color: #ffcc67;
                text-align: center;
                vertical-align: top;
                text-decoration: underline;
                font-weight: bold;
              }
            }
          }

          > .timer {
            margin-bottom: 36px;
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }

        > .label {
          color: $grey500;
          margin-bottom: 8px;
        }
      }

      > .separater-right {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(
          100vh - 120px - 99px - 80px
        ); /*전체 박스에서 상하단 padding제외*/
        overflow-y: auto;
        padding-right: 20px;
        > .label {
          color: $grey500;
          margin-bottom: 16px;
        }

        > .answer {
          > ul.answer-list {
            > li.answer-list-item {
              border: 1px solid $grey100;
              border-radius: 4px;
              cursor: pointer;

              &:hover {
                background: $grey50;
              }

              &:not(&:last-child) {
                margin-bottom: 16px;
              }

              > .container {
                display: flex;
                align-items: center;
                padding: 4px;
                border-radius: 4px;
                > p {
                  // word-break: break-all;
                  word-break: keep-all;
                  word-wrap: break-word;
                }
                .MuiMenuItem-root {
                  padding: 0 !important;
                }
                > .hasImage {
                  width: 100%;
                  text-align: center;
                  > img {
                    max-width: 100%;
                    max-height: 160px;
                  }
                }
              }

              &.selected {
                > .container {
                  background-color: $blue500;

                  > .radio-button {
                    svg {
                      color: white;
                      > path {
                      }
                    }
                  }

                  > p {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// 영어 독해
.pages-protected-evaluation-objectives-common-english-question {
  &.wide {
    display: block !important;
    text-align: center;

    .separater-left {
      width: 100% !important;
      height: auto !important;
    }
    .separater-right {
      width: 100% !important;
      height: auto !important;
    }
  }

  .separater-left {
    .multiple-question {
      margin: 0 !important;
      margin-bottom: 16px !important;
    }

    .question {
      p {
        margin: 0;
        line-height: 1.8 !important;
        font-weight: 500 !important;
        color: $grey900;
      }
    }
  }

  .separater-right {
    .question {
      margin-bottom: 16px;

      p {
        font-weight: 700;
      }
    }

    .answer {
      ul.answer-list {
        li.answer-list-item {
          .container {
            padding: 16px !important;

            .radio-button {
              padding: 0;
              padding-right: 16px;
            }
          }
        }
      }
    }
  }
}

// 영어 듣기
.pages-protected-evaluation-objectives-common-listening {
  display: block !important;
  height: calc(100vh - 120px - 99px - 80px);
  overflow: auto;

  .separater-wrapper {
    .question-desc {
      text-align: center;

      h4 {
        font-weight: 700;
        margin-bottom: 8px;
      }
      .button-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: center;

        button {
          display: inline-block;
          vertical-align: middle;
          padding: 18.5px 24px;
          border-radius: 32px;
          font-weight: 500;

          &:disabled {
            background-color: $blue200;
            color: $white;
          }

          i {
            display: inline-block;
            vertical-align: middle;
            padding: 3.75px 4.88px;
            margin-right: 8px;
          }
        }

        .information {
          color: $grey600;
        }
      }
    }

    .question {
      text-align: center;

      .question-item {
        margin-top: 64px;

        &.inactive {
          opacity: 0.4;

          .answer-list {
            .answer-list-item {
              cursor: default !important;
              pointer-events: none;
            }
          }
        }
        &:not(:first-child) {
          margin-top: 36px;
        }

        .question-title {
          font-weight: 700;
          margin-bottom: 8px;
        }

        .question-image {
          margin-bottom: 8px;

          img {
            max-width: auto;
            max-height: 240px;
          }
        }

        .answer-list {
          display: flex;
          align-items: center;
          width: 100%;
          border-radius: 8px;
          overflow: hidden;
          border: 1px solid $grey300;

          .answer-list-item {
            flex: 1;
            padding: 10px;
            cursor: pointer;
            transition: all 0.2s;
            font-weight: 500;

            &.disabled {
              cursor: default;
              opacity: 0.5;

              &:hover {
                background-color: transparent;
              }
            }
            &:not(:last-child) {
              border-right: 1px solid $grey300;
            }

            &:hover {
              background-color: $grey50;
            }

            &.selected {
              background-color: $blue500;
              color: $white;
            }
          }
        }
      }
    }
  }
}

.pages-protected-evaluation-subjectives-multiple-choice {
  &.subjective {
    > .container {
      > .separater {
        > .separater-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    width: 1040px;
    padding: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    &.more-width {
      width: 100%;
    }

    > .separater {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 40px;

      > .separater-left {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(
          100vh - 120px - 99px - 80px
        ); /*전체 박스에서 상하단 padding제외*/
        overflow-y: auto;
        padding-right: 20px;

        > .question {
          // font-weight: bold;
          line-height: 23.2px;
          color: $grey900;
          margin-bottom: 8px;
          word-break: keep-all;
          word-wrap: break-word;
          overflow-y: auto;
          // background-color: $grey50;
          // padding: 20px;

          > * {
            all: revert;
            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              margin-top: 16px;
              width: 100%;
            }
          }

          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 10px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .timer {
            margin-bottom: 36px;
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }

        > .label {
          color: $grey500;
          margin-bottom: 8px;
        }
      }

      > .separater-right {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(
          100vh - 120px - 99px - 80px
        ); /*전체 박스에서 상하단 padding제외*/
        overflow-y: auto;
        padding-right: 20px;
        > .label {
          color: $grey500;
          margin-bottom: 16px;
        }

        > .answer {
          > ul.answer-list {
            > li.answer-list-item {
              border: 1px solid $grey100;
              border-radius: 4px;
              cursor: pointer;

              &:hover {
                background: $grey50;
              }

              &:not(&:last-child) {
                margin-bottom: 16px;
              }

              > .container {
                display: flex;
                align-items: center;
                padding: 4px;
                border-radius: 4px;
                > p {
                  // word-break: break-all;
                  word-break: keep-all;
                  word-wrap: break-word;
                }
                .MuiMenuItem-root {
                  padding: 0 !important;
                }
                > .hasImage {
                  width: 100%;
                  text-align: center;
                  > img {
                    max-width: 100%;
                    max-height: 160px;
                  }
                }
              }

              &.selected {
                > .container {
                  background-color: $blue500;

                  > .radio-button {
                    svg {
                      color: white;
                      > path {
                      }
                    }
                  }

                  > p {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-obpitest {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 64px;
    width: 1040px;
    background: $white;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    overflow-y: auto;
    > .container-inner {
      width: 100%;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 64px;

      &.khai-O {
        .testlist {
          text-align: center;

          .answer {
            display: flex;
            justify-content: center;
            .answer-list {
              max-width: 480px;
            }
          }
        }
      }
      > .discription {
        width: 100%;
        height: 23px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: $grey700;

        &.khai {
          height: auto;
          align-items: flex-start;
          flex-direction: column;
          gap: 16px;
        }
      }
      > .testlist {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        > .question {
          width: 100%;
          font-style: normal;
          font-weight: 700;
          align-items: center;
          text-align: center;
          color: $grey900;
          overflow-y: auto;
          // background-color: $grey50;
          // padding: 20px;

          > * {
            all: revert;
            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              margin-top: 16px;
              width: 100%;
            }
          }

          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;

            * {
              all: revert;
              margin: 0 !important;
            }
          }
        }
        > .answer {
          width: 100%;
          > .answer-list {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 0px;
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid $grey100;

            .answer-list-item {
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 24px;
              gap: 16px;
              flex: 1;
              background: $white;
              // border: 1px solid $grey100;
              cursor: pointer;
              font-size: 0;
              width: 100%;
              transition: all 0.2 ease-in-out;

              > span {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                align-items: center;
                text-align: center;
                color: $grey900;
              }

              &:not(:last-child) {
                border-right: 1px solid $grey100;
              }

              &:hover {
                background: $grey100;
              }
              &.selected {
                background: $grey900;
                border-color: $grey900;
                > span {
                  color: $white;
                }
              }
              &.disabled {
                border: 1px solid $grey100;
                color: $grey300;
                cursor: default;
              }
            }
            .answer-khai-list-item {
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 24px;
              gap: 16px;
              flex: 1;
              background: $white;
              cursor: pointer;
              width: 100%;
              transition: all 0.2 ease-in-out;

              > span {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                align-items: center;
                text-align: center;
                color: $grey900;
              }

              &:not(:last-child) {
                border-right: 1px solid $grey100;
              }

              &:hover {
                background: $grey100;
              }
              &.selected {
                background: $grey900;
                border-color: $grey900;
                > span {
                  color: $white;
                }
              }
              &.disabled {
                border: 1px solid $grey100;
                color: $grey300;
                cursor: default;
              }
            }
          }
        }
      }
      > .button-next {
        > button {
          display: flex;
          height: 56px;
          justify-content: center;
          padding: 8px 24px;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
        }
      }
    }
  }
}

.pages-protected-evaluation-subjectives-file-upload {
  &.subjective {
    > .container {
      > .container-inner {
        > .separater {
          > .separater-left {
            > .question {
              background-color: $white !important;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 64px;
    width: 1040px;
    background: $white;
    border-radius: 8px;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/

    &.more-width {
      width: 100%;
    }

    > .container-inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
      width: 100%;
      > .separater {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 40px;

        > .separater-left {
          flex: 1;
          width: calc(50% - 20px);
          height: calc(
            100vh - 120px - 99px - 80px
          ); /*전체 박스에서 상하단 padding제외*/
          overflow-y: auto;
          padding-right: 20px;

          > .question {
            // font-size: 18px;
            // font-weight: bold;
            // line-height: 23.2px;
            word-break: keep-all;
            word-wrap: break-word;
            margin-bottom: 8px;
            overflow-y: auto;
            // background-color: $grey50;
            // padding: 20px;

            > * {
              all: revert;
              img {
                margin-top: 16px;
                max-width: 100%;
                height: auto;
                margin-top: 16px;
                width: 100%;
              }
            }

            > pre {
              white-space: pre-wrap;
              margin: 0;
              margin-bottom: 8px;
              line-height: 1.8 !important;
              // text-indent: 10px;

              * {
                all: revert;
                margin: 0 !important;
              }
            }

            > .timer {
              // font-size: 18px;
              margin-bottom: 36px;

              &::before {
                content: url('/assets/images/icon/timer.svg');
                margin-right: 7px;
                position: relative;
                top: 4px;
              }
            }

            > .video-box {
              margin-bottom: 32px;
              .question-video {
                display: block;
                width: 100%;
              }
            }
          }
        }

        > .separater-right {
          flex: 1;
          width: calc(50% - 20px);
          height: calc(
            100vh - 120px - 99px - 80px
          ); /*전체 박스에서 상하단 padding제외*/
          overflow-y: auto;
          padding-right: 20px;

          > .answer {
            width: 100%;
            height: 200px;
            border-radius: 16px;
            background-color: $grey100;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              background-color: rgba($grey100, 0.6);
            }

            &:active {
              background-color: rgba($grey100, 0.8);
            }

            > .file-upload-icon {
              margin-bottom: 16px;
            }

            > .file-upload-name {
              margin-top: 8px;
            }
          }
        }
      }

      > button.submit {
        width: 460px;
        height: 56px;
        border-radius: 32px;
      }
    }
  }
}

.pages-protected-evaluation-subjectives-spreadsheets {
  > .container {
    width: 100%;
    display: flex;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    overflow-y: auto;
    > .container-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      padding-right: 20px;

      > .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;

        > .header-question {
          // font-size: 18px;
          font-weight: bold;
        }

        > .header-submit {
          height: 56px;
          border-radius: 32px;
          padding: 0 40px;
        }
      }

      > .spreadsheets {
        // flex: 1;
        .form-spreadsheet {
          position: relative;
          width: 100%;

          > .cover {
            position: absolute;
            top: 0;
            left: 0;
            height: 64px;
            width: 100%;
            background-color: $grey50;
            z-index: 10;
          }

          #docs-header-container {
            visibility: hidden;
          }

          #docs-menubars {
            visibility: hidden;
          }
          > iframe {
            width: 100%;
            height: calc(100vh - 340px);
          }
        }
      }
    }
  }
}

.pages-protected-evaluation-objectives-culture-fit {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 64px;
    width: 1040px;
    background: $white;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    overflow-y: auto;

    > .container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
      width: 100%;
      padding-right: 20px;

      > .title {
        // font-size: 20px;
        font-weight: bold;
      }

      > .attributes {
        > ul.attributes-list {
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;
          // gap: 16px;
          display: grid;
          grid-template-columns: repeat(auto-fill, 160px);
          grid-gap: 16px;
          justify-content: space-between;

          > li.attributes-list-item {
            > .container {
              cursor: pointer;
              width: 179px;
              padding: 16px;
              display: inline-flex;
              flex-direction: column;
              border: 1px solid $grey100;
              transition: border 0.1s, background-color 0.1s;
              border-radius: 4px;

              > span {
                transition: color 0.1s;

                &:nth-child(1) {
                  color: $grey900;
                  margin-bottom: 4px;
                }

                &:nth-child(2) {
                  font-size: 12px;
                  color: $grey500;
                }
              }

              &:hover {
                border: 1px solid $primary_color;
              }
            }

            &.selected {
              > .container {
                background-color: $primary_color;

                &:hover {
                  background-color: rgba($primary_color, 0.6);
                  border: 1px solid transparent;
                }

                > span {
                  color: white;
                }
              }
            }

            &.disabled {
              > .container {
                cursor: default;

                &:hover {
                  border: 1px solid $grey100;
                }

                > span {
                  color: $grey300;
                }
              }

              &.selected {
                > .container {
                  cursor: pointer;

                  &:hover {
                    background-color: rgba($primary_color, 0.6);
                  }

                  > span {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }

      > button.submit,
      > button.next {
        width: 460px;
        height: 56px;
        border-radius: 32px;
        margin: 0 auto;
      }
    }
  }
}

.pages-protected-evaluation-subjectives-styling {
  padding: 0px !important;

  > .container {
    width: 1040px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(
      100vh - 104px - 80px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    &.more-width {
      width: 100%;
      padding: 0;

      .tox-tinymce {
        height: 100% !important;
      }
    }

    > .spliter {
      width: 100%;
      display: flex;
      // gap: 10px;
      height: 100%;

      > .spliter-left {
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 34px 45px;
        gap: 48px;
        width: 100%;
        flex: 4;
        background-color: #f8f7f2;

        > .question {
          max-height: 100%;
          min-height: 740px;
          width: 100%;
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          gap: 48px;

          > * {
            all: revert;
            img {
              max-width: 100%;
              height: auto;
              margin-top: 16px;
              width: 100%;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            line-height: 1.8 !important;
            // text-indent: 10px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .styling-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: 74%; /* 20.72px */
              letter-spacing: -1px;
            }

            .reset-button {
              svg {
                path {
                  fill: $white;
                }
              }

              &:disabled {
                color: $grey400;
                background-color: $grey300;

                svg {
                  path {
                    fill: $grey400;
                  }
                }
              }
            }
          }

          > .base {
            width: 460px;
            height: 550px;
            display: flex;
            position: relative;
            margin: 0 auto;
            // background: url('/assets/images/icon/styling.svg') no-repeat;
            background-color: #f8f7f2;

            &.en {
              // background: url('/assets/images/icon/styling-en.svg') no-repeat;
            }
            > .topsNeck {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 0;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .outerwearNeck {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 0;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .mannequin-body {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 1;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .mannequin-right-arm {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 0;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .mannequin-left-arm {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 0;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-socks {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 2;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .outerwear {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 100;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .shoes {
              position: absolute;
              max-width: 560px;
              width: 150px;
              height: 150px;
              left: 0;
              bottom: 0;
              z-index: 30;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .tops {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 5;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.active {
                z-index: 10;
              }
              // &.short-dress {
              //   width: 300px;
              //   height: 300px;
              //   left: 114px;
              //   top: 123px;
              // }

              // &.long-dress {
              //   width: 380px;
              //   height: 380px;
              //   left: 72px;
              //   top: 143px;
              // }

              // &.white-dress {
              //   width: 380px;
              //   height: 380px;
              //   left: 72px;
              //   top: 128px;
              // }

              // &.jump-suits {
              //   left: 137px;
              //   top: 142px;
              // }
            }
            > .bottoms {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 50;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.down {
                z-index: 5;
              }
            }
            > .bottomsSkirts {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 0;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.down {
                z-index: 0;
              }
            }
            > .handbags {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 101;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-belt {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 51;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-ring {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 45;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-bracelets {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 4;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-brooches {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 101;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-eyewear {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 103;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-hair {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 105;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.active {
                z-index: 102;
              }
            }
            > .accessories-watch {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 4;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-gloves {
              position: absolute;
              max-width: 560px;
              width: 150px;
              height: 150px;
              right: 0;
              top: 30%;
              z-index: 20;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-tie {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 64;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
          > .timer {
            margin-bottom: 36px;
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }
        }
      }
      > .spliter-right {
        flex: 6;
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;

        > .gender-tabs {
          border-bottom: 1px solid $grey100;

          > .gender-tabs {
            display: flex;
            align-items: center;

            > .gender {
              position: relative;
              padding: 20px 24px;
              cursor: pointer;
              font-weight: 700;
              font-size: 16px;
              line-height: 145%;
              color: $grey900;
              transition: background-color 0.2s;

              &:hover {
                background-color: $grey50;
              }

              &::after {
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                content: '';
                width: 0;
                height: 2px;
                background-color: $blue500;
              }

              &.selected {
                color: $blue500;

                &::after {
                  width: 40%;
                }
              }
              &.checked {
                &::after {
                  margin-left: 4px;
                  margin-top: 5px;
                  content: url('/assets/images/icon/check_circle.svg');
                }
              }
            }
          }
        }

        > .contents {
          max-width: 760px;
          margin: 0 auto;

          > .tab {
            padding: 0px;
            overflow-x: auto;

            > .tabs {
              display: flex;
              justify-content: space-between;
              gap: 30px;

              > .item {
                flex-direction: row;
                padding: 8px 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 145%;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: $grey900;
                cursor: pointer;
                transition: background-color 0.2s;

                &:hover {
                  background-color: $grey50;
                }

                &.selected {
                  color: $grey800;
                  font-weight: 500;
                  border-bottom: $grey800 2px solid;
                }
                &.checked {
                  color: $grey800;
                  font-weight: 500;
                  border-bottom: $grey800 2px solid;
                }
              }

              @media #{$pcSmall} {
                &.tabs {
                  gap: 10px;
                }
              }
            }
          }
          > .divider {
            width: 2px;
            height: 100%;
            background: $grey200;
          }
          > .itemlist {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 46px;
            gap: 24px;
            width: (100%);
            height: calc(100vh - 100px - 80px - 86px - 28px);
            overflow-y: auto;
            background: #ffffff;

            > .items {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 16px;
              width: 100%;
              // height: 315px;
              > .title {
                width: 100%;
                height: 23px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 145%;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: #2e3338;
              }
              > .images {
                display: grid;
                align-items: flex-start;
                grid-template-columns: repeat(auto-fill, 175px);
                padding: 0px;
                gap: 12px;
                width: 100%;
                justify-content: flex-start;

                > .product {
                  box-sizing: border-box;
                  width: 175px;
                  height: 240px;
                  background: #e7e7e7;
                  border-radius: 8px;
                  display: flex;
                  position: relative;
                  border: 2px solid transparent;
                  align-items: center;
                  cursor: pointer;

                  &:hover {
                    border: 2px solid $grey300;
                  }

                  > .check-icon {
                    position: absolute;
                    width: 26px;
                    height: 26px;
                    right: 14px;
                    top: 10px;
                    display: none;
                  }

                  &.selected {
                    background-color: #b9b9b9;
                    > .check-icon {
                      display: block;
                    }
                  }

                  > img {
                    width: 100%;
                    height: auto;
                  }
                }

                @media #{$pcSmall} {
                  &.images {
                    grid-template-columns: repeat(auto-fill, 135px);

                    > .product {
                      width: 135px;
                      height: 180px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.single-chart {
  width: 100%;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: $grey100;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 3.8;
  stroke-linecap: butt;
  animation: progress 1s ease-out forwards;
  transition: stroke-dashoffset 0.2s ease-in-out;
}

@keyframes progress {
  0% {
    stroke-dashoffset: 0 100;
  }
}

.circular-chart.blue .circle {
  stroke: $blue500;
}
.circular-chart.red .circle {
  stroke: $red500;
}
.circular-chart.orange .circle {
  stroke: $orange500;
}

.pages-protected-evaluation-done {
  .header-img {
    width: 240px;
    height: 240px;
    background-image: url('/assets/images/illustration/img-final-evaluation.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
  }

  .header-description {
    color: $grey900;
    line-height: 23.2px;
    text-align: center;
    > .center {
      display: inline-block;
      margin: 16px auto 0;
    }
  }

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 32px;
    width: 1040px;
    background: $white;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      width: 100%px;
      > .header-title {
        // width: 960px;
        font-weight: 700;
        display: flex;
        align-items: center;
        text-align: center;
        color: $grey900;
      }
      > .header-description {
        margin-top: 8px;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: $grey900;
      }
      > .objective-result {
        width: 100%;
        align-items: center;
        margin-top: 32px;
        > .average-score {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 360px;
          height: 186px;
          margin: 0 auto;
          background: $white;
          border: 1px solid $grey100;
          border-radius: 4px;
          > .average-score-title {
            height: 26px;
            font-weight: 700;
            display: flex;
            align-items: center;
            text-align: center;
            color: $grey900;
          }
          > .average-score-graph {
            margin-top: 8px;
            width: 120px;
            height: 120px;
            text-align: center;
            > .percentage {
              position: relative;
              bottom: 71%;
              > .number {
                font-weight: 700;
              }
              > .unit {
                font-weight: 700;
              }
            }
          }
        }
        > .result-objective {
          width: 100%;
          margin-top: 32px;
          > .result-objective-title {
            // font-size: 20px;
            font-weight: bold;
            color: $grey900;
            margin-bottom: 16px;
          }

          > .result-objective-table {
            thead {
              border: 1px solid $grey300;
            }
            tr {
              border-left: 1px solid $grey300;
              border-right: 1px solid $grey300;
              border-bottom: 1px solid $grey300;
            }
            .thead {
              // font-size:16px;
              font-weight: 700;
              // letter-spacing: -0.01em;
              &.title {
              }

              &.score {
              }

              &.survey {
              }
            }

            .tbody {
              font-weight: 500;
              &.title {
              }

              &.category {
                padding-left: 32px;
              }

              &.score {
                > .container {
                  > span {
                  }

                  > .progress-bar {
                    position: relative;
                    width: 100%;
                    height: 4px;
                    background-color: $grey100;
                    border-radius: 4px;

                    > .fill {
                      height: 4px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      border-radius: 4px;
                      &.blue {
                        background-color: $blue500;
                      }
                      &.red {
                        background-color: $red500;
                      }
                      &.orange {
                        background-color: $orange500;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      > .obpi-result {
        margin-top: 32px;
        width: 100%;
        > .obpi-result-title {
          width: 100%;
          height: 26px;
          font-style: normal;
          font-weight: 700;
          display: flex;
          align-items: center;
          color: $grey900;
        }
        > .obpi-detail-result {
          margin-top: 8px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 40px;
          gap: 24px;
          width: 100%;
          border: 1px solid $grey100;
          border-radius: 8px;
          > .obpi-detail-result-title {
            width: 100%;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }
          > .obpi-detail-result-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;
            width: 100%;
            > .obpi-detail-result-title {
              width: 100%;
              font-style: normal;
              font-weight: 700;
              align-items: center;
              text-align: center;
              color: $grey900;
            }
            > .obpi-detail-results {
              > .obpi-detail-result-answer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                padding: 0px;
                gap: 4px;
                width: 880px;
                height: 16px;
                > .obpi-detail-result-answer-item {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  width: 94.22px;
                  height: 16px;
                  background: $grey100;
                  border-radius: 4px;
                  &.selected {
                    background: $blue500;
                    &::after {
                      width: 12px;
                      height: 12px;
                      display: inline-block;
                      content: '';
                      -webkit-mask: url('/assets/images/icon/Star.svg')
                        no-repeat 50% 50%;
                      mask: url('/assets/images/icon/Star.svg') no-repeat 50%
                        50%;
                      -webkit-mask-size: cover;
                      mask-size: cover;
                      background-color: $white;
                    }
                  }
                }
              }
            }
            > .opbi-detail-result-discription-box {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              padding: 0px;
              gap: 4px;
              width: 880px;
              height: 16px;
              > .discription {
                width: 438px;
                height: 16px;
                font-style: normal;
                font-weight: 700;
                align-items: center;
                color: $grey700;
                &.left {
                  text-align: left;
                }
                &.right {
                  text-align: right;
                }
              }
            }
          }
          > .obpi-detail-result-error {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 100%;
            > .title {
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .desc {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 145%;
              display: flex;
              align-items: center;
              color: $grey900;
              > a {
                color: $blue500;
                margin-left: 5px;
                text-decoration: none;
              }
            }
          }
        }
      }

      > .footer {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 8px;
        width: 960px;
        height: 71px;
        background: $grey50;
        border-radius: 4px;
      }
    }
  }
}

.pages-protected-evaluation-subjectives-front-code {
  > .container {
    width: 1040px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: calc(100vh - 120px - 99px); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    > .spliter {
      width: 100%;
      height: 100%;
      > .spliter-left {
        width: 50%;
        padding: 40px;
        box-sizing: border-box;
        height: 100%;
        overflow-y: auto;
        > .question {
          // max-height: calc(100vh - 120px - 99px - 80px - 33px);
          // font-weight: bold;
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
          height: 100%;
          // background-color: $grey50;
          // padding: 20px;

          > pre {
            word-break: keep-all;
            word-wrap: break-word;
            color: $grey900;
            font-weight: 700;
            line-height: 1.8 !important;
            // text-indent: 10px;
          }
        }

        > .timer {
          &::before {
            content: url('/assets/images/icon/timer.svg');
            margin-right: 7px;
            position: relative;
            top: 4px;
          }
        }
      }

      > .spliter-right {
        // width: 50%;
        width: 100%;
        // max-height: calc(100vh - 120px - 99px - 33px);
        height: 500px;

        > .tab {
          ul {
            display: flex;
            align-items: center;

            li {
              padding: 20px 24px;
              font-weight: 700;
              cursor: pointer;
              position: relative;
              transition: all 0.2s;

              &:hover {
                color: $blue500;
              }

              &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                display: block;
                width: 0;
                height: 2px;
                background-color: $blue500;
                transition: all 0.2s;
              }

              &.active {
                color: $blue500;

                &::after {
                  width: 45px;
                }
              }
            }
          }
        }

        .editor-select {
          // height: 48px;
          padding: 8px 16px;
          background-color: $grey800;
          position: relative;
          > .question-language-select-box
            > .question-language-select-caution
            > span {
            color: $white;
          }

          > span {
            display: block;
            background-color: $grey700;
            color: $white;
            padding: 6px 8px;
            border-radius: 4px;
          }
        }

        .editor-input {
          position: relative;
          height: calc((100% - 63.2px - 56.2px) * 0.7);

          * {
            font-family: Consolas, source-code-pro, Menlo, Monaco, 'Courier New',
              monospace !important;
          }
        }

        .editor-result-button-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;
          background-color: $grey800;
          margin-top: -1px;

          > span {
            display: inline-block;
            font-weight: bold;
            color: $white;
          }
          > button {
            padding: 10.5px 12px;
            border-radius: 4px;
            display: flex;
            align-items: center;

            > svg {
              margin-left: 10px;
            }
          }
          > button[disabled] {
            background-color: $blue200;
            cursor: default;
          }
        }

        .editor-output {
          margin-top: -1px;
          position: relative;
          height: calc((100% - 63.2px - 56.2px) * 0.3);

          #iframe-preview {
            width: 100%;
            height: 100%;
          }
        }

        .output-result-box {
          background-color: $grey900;
          height: 100%;
          padding: 8px 16px;
          overflow-y: auto;

          > p,
          pre {
            color: $white;
          }
        }

        #mceu_15 {
          min-height: 160px;
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// component
// PieChart.js
.score-graph {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  position: relative;
  > .percentage {
    text-align: center;
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);

    > .number {
      font-weight: 700;
    }
    > .unit {
      font-weight: 700;
    }
  }
}

.single-chart {
  width: 100%;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  max-width: 100%;
  max-height: 250px;
  transform: rotateY(180deg);
}

.circle-bg {
  fill: none;
  stroke: $grey100;
  stroke-width: 1;
}

.circle {
  fill: none;
  stroke: $blue500;
  stroke-width: 1;
  stroke-linecap: butt;
  transition: all 0.2s ease-in;
}

.final-alert-modal {
  position: fixed;
  top: 10px;
  right: -110%;
  z-index: 9999;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.loading {
    right: 10px;
  }
  &.finished-loading {
    right: 10px;
  }
  &.hide {
    right: -110% !important;
  }

  > .final-alert-text-box {
    background-color: $grey900;
    color: $white;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      color: $white;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      letter-spacing: 0.16px;
      white-space: pre-wrap;
    }
  }
}

//Demo test taker css
.pages-demo-voice-test {
  height: calc(100vh - 80px - 104px);
  background-color: $grey100;
  padding-bottom: 40px !important;
  .container {
    .setting-title {
      color: $grey900;
      text-align: center;
      padding: 40px;
      font-size: 2.604vw;
      font-style: normal;
      font-weight: 500;
      line-height: 74%;
      letter-spacing: -2px;
      margin-bottom: 30px;
    }
    .container-wrapper {
      display: flex;
      justify-content: center;
      gap: 120px;

      .container-right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        .header {
          margin-bottom: 28px;
          color: $grey800;
          font-size: 1.042vw;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.2px;
        }

        .read-sentence {
          margin-bottom: 46px;
          .read-sentence-wrapper {
            border: 1px solid $grey300;
            padding: 12px 20px;
            width: 34.896vw;
            background: $grey200;
            text-align: center;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.32px;

            p {
              font-weight: 700;
              background: var(
                --black-gradient,
                linear-gradient(90deg, #000 5.59%, rgba(0, 0, 0, 0) 108.38%)
              );
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              background-size: 300% auto;
              animation: text-gradient 4s ease-in-out infinite;
              -webkit-animation: text-gradient 4s ease-in-out infinite alternate;
            }

            @keyframes text-gradient {
              0% {
                background-position: 0% 50%;
              }
              100% {
                background-position: 100% 50%;
              }
            }
          }
        }

        .button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 8px;

          .submit {
            width: 100%;
            max-width: 600px;
            height: 80px;
            border-radius: 50px;
            text-align: center;
            color: white;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 75%;
            letter-spacing: -0.4px;
          }

          .loading {
            text-align: center;
            background-color: $grey300;
            color: $grey900;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 24px;
          }

          .helper {
            p {
              color: $grey800;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }

      .container-left {
        .video-stream-contents {
          .video-container {
            display: flex;
            flex-direction: column;

            .cam-guide-box {
              margin-bottom: 12px;
              font-size: 12px;
              font-weight: 600;
              color: $grey900;
            }

            .video-box {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0;
              margin-bottom: 12px;
              position: relative;

              .video {
                height: 300px;
                transform: rotateY(180deg);
              }

              .face-canvas {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 50%;
                transform: rotateY(180deg) translateX(50%);
              }

              .record-time {
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
                z-index: 1;
                padding: 4px 0;

                * {
                  color: $white;
                  fill: $white;
                  font-weight: 700;
                }
              }

              .video-bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                overflow: hidden;
                .video-bg-rect {
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 280px;
                  height: 200px;
                  position: absolute;
                  border: 1px solid $white;
                  border-radius: 8px;

                  &::after {
                    overflow: hidden;
                    top: -2px;
                    left: -2px;
                    content: '';
                    position: absolute;
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                    border-radius: 8px;
                    box-shadow: 0 0 0 999px rgba($black, 0.2);
                  }
                }
              }


            }
            .media-setting-alert {
              border: 1px solid $grey300;
              background: $grey50;
              padding: 12px 20px;
              width: 100%;
              justify-content: center;
              align-items: center;
              display: flex;
              gap: 10px;

              &:hover {
                background-color: $grey100;
              }
            }
          }
        }
      }
    }
  }
}

.demo-pages-protected-evaluation-done {
  .container {
    display: flex;
    gap: 88px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    z-index: 10000;
    height: 100vh;
    background: black;
    top: 0;
    color: white;
    text-align: center;

    > :nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 40px;
      > :first-child {
        font-size: 52px;
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: 0.52px;
      }
      > :nth-child(2) {
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 56px;
        letter-spacing: 0.24px;
      }
    }
  }
}

.demo-pages-protected-evaluation-subjectives-video {
  padding: 0px !important;
  &.subjective {
    > .container {
      position: relative;
      > .spliter {
        > .spliter-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    padding: 60px 100px;
    max-width: 1920px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 80px - 104px);
    position: relative;

    &.role-playing {
      padding: 0;
      max-width: none;
    }

    &.more-width {
      width: 100%;

      > .spliter {
        > .spliter-right {
          > .video {
            max-height: none;
            width: 800px;
          }
        }
      }
    }

    > .question-modal {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(8px);
      width: 100%;
      height: 100%;
      z-index: 0;
      transition: all 0.3s ease-in-out;
      border-radius: 8px;
      padding: 40px;
      text-align: center;

      &.show {
        transform: scale(1);
        z-index: 30;
      }

      &.hide {
        transform: scale(0);
      }
    }

    > .spliter {
      display: flex;
      width: 100%;
      gap: 40px;

      > .spliter-left {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(100vh - 120px - 99px - 80px);
        overflow-y: auto;
        padding-right: 20px;

        &.role-playing {
          background: $grey200;
          height: calc(100vh - 104px - 80px);

          > .question {
            padding: 34px 45px;
            height: 100%;

            hr {
              border: 0;
              height: 1px;
              background: $grey500;
              margin: 30px 0;
            }
            pre {
              color: $grey800;
              font-size: 21px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px;
            }
          }
        }

        > .question {
          height: 50%;
          line-height: 23.2px;
          margin-bottom: 24px;
          overflow-y: auto;
          word-break: keep-all;
          word-wrap: break-word;
          font-size: 24px;

          > .role-title {
            color: $grey900;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            letter-spacing: -0.56px;
            margin-bottom: 30px;
          }

          > * {
            all: revert;
            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              max-height: 500px;
              margin-top: 16px;
              width: 100%;
            }
          }

          > a {
            display: block;
            width: 100%;
            text-align: center;

            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              max-height: 500px;
              margin-top: 16px;
              height: 100%;
              width: auto;
              margin-left: auto;
              margin-right: auto;
            }
          }

          > pre {
            font-size: 18px !important;
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }

        > .demo-timer {
          display: flex;
          justify-content: center;
          background-color: $grey200;
          border-radius: 8px;
          padding: 32px 0px 50px 0px;

          .timer-wrapper {
            display: flex;
            flex-direction: column;
            .timer-title {
              color: $grey900;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: -0.2px;
              margin-bottom: 12px;
            }

            .time-box {
              display: flex;
              color: white;
              text-align: center;
              font-size: 40px;
              font-style: normal;
              font-weight: 500;
              line-height: 75%;
              letter-spacing: -0.4px;
              margin-bottom: 15px;
              gap: 8px;
              .timer-container {
                display: flex;
                flex-direction: column;
                .time-title {
                  display: flex;
                  color: $grey700;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  letter-spacing: -0.16px;
                  margin-bottom: 15px;
                }
                > .timer-box {
                  border-radius: 4px;
                  background: black;
                  height: 90px;
                  padding: 20px 30px;
                  align-items: center;
                  gap: 24px;
                  flex: 1;
                  display: flex;

                  > div {
                    width: 50px;
                  }

                  > .timer-line {
                    width: 1px;
                    background: #454d54;
                    height: 60px;
                  }
                }
              }
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: calc(50% - 20px);
        height: calc(100vh - 120px - 99px - 80px);
        overflow-y: auto;

        &.role-playing {
          padding: 30px 60px;
          height: calc(100vh - 104px - 80px);

          & > * {
            margin: 0 auto;
          }

          > .video {
            width: 80%;
            height: 50%;
            display: block;
          }

          > .buttons {
            width: 80%;
          }

          > .record-cnt {
            width: 80%;

            &.start {
              display: block !important;
            }
          }

          > .record-helper {
            width: 80%;
          }

          > .demo-timer {
            width: 80%;
            padding: 24px 0px 40px 0px;
          }
        }

        > .demo-timer {
          display: flex;
          justify-content: center;
          background-color: $grey200;
          border-radius: 8px;
          padding: 32px 0px 50px 0px;

          .timer-wrapper {
            display: flex;
            flex-direction: column;
            .timer-title {
              color: $grey900;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: -0.2px;
              margin-bottom: 12px;
            }

            .time-box {
              display: flex;
              color: white;
              text-align: center;
              font-size: 40px;
              font-style: normal;
              font-weight: 500;
              line-height: 75%;
              letter-spacing: -0.4px;
              margin-bottom: 15px;
              gap: 8px;
              .timer-container {
                display: flex;
                flex-direction: column;
                .time-title {
                  display: flex;
                  color: $grey700;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  letter-spacing: -0.16px;
                  margin-bottom: 15px;
                }
                > .timer-box {
                  border-radius: 4px;
                  background: black;
                  height: 90px;
                  padding: 20px 30px;
                  align-items: center;
                  gap: 24px;
                  flex: 1;
                  display: flex;

                  > div {
                    width: 50px;
                  }

                  > .timer-line {
                    width: 1px;
                    background: #454d54;
                    height: 60px;
                  }
                }
              }
            }
          }
        }

        > .video {
          width: 100%;
          max-height: 400px;
          background-color: $black;
          margin-bottom: 32px;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          position: relative;
          border-radius: 8px;

          > video {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            transform: rotateY(180deg);
          }

          video::-webkit-media-controls {
            transform: rotateY(180deg) !important;
          }

          &.start {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: hidden;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-timeline {
                visibility: hidden;
              }
            }
          }

          &.stop {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: visible;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: visible;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: visible;
              }

              &::-webkit-media-controls-timeline {
                visibility: visible;
              }
            }
          }
        }

        > .alert-demo {
          padding: 16px;
          background-color: $grey50;
          border-radius: 8px;
          margin-bottom: 32px;

          &.stop {
            display: none;
          }
        }

        > .buttons {
          display: flex;
          gap: 24px;
          margin-bottom: 32px;

          > button {
            flex: 1;
            height: 56px;
            border-radius: 32px;
            background: $grey900;
            font-weight: 700;

            &:disabled {
              cursor: default;
              background-color: $grey300;
            }
          }
        }
        > .record-cnt {
          color: #1a1a1a;
          font-size: 24px;
          text-align: center;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          margin-bottom: 20px;
        }

        > .record-helper {
          text-align: center;
          margin-bottom: 40px;

          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%; /* 23.2px */
            letter-spacing: -0.16px;
            color: $grey900;

            i {
              color: $red500;
            }
          }
        }

        > .record-grey-box {
          margin-bottom: 40px;

          p {
            text-align: center;
            padding: 12px 20px;
            background-color: $grey200;
            border: 1px solid $grey300;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 20px */
            letter-spacing: -0.2px;
          }
        }

        > .alert {
          padding: 16px;
          border-radius: 8px;
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: space-between;

          &.demo {
            gap: 8px;
            padding: 0;
            justify-content: left;
            margin-bottom: 16px;
          }

          &.start {
            display: none;
          }

          > .alert-title {
            font-weight: 500;
          }
        }
      }
    }

    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// survey 페이지
.demo-pages-protected-evaluation-test-end {
  padding: 0 !important;
  background: $grey150;
  position: relative;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 10px;
    width: 100%;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      width: 100%;

      > .title {
        font-size: 60px;
        font-style: normal;
        font-weight: 500;
        line-height: 74%; /* 59.2px */
        letter-spacing: -3px;
        margin-bottom: 80px;
      }

      > .description {
        color: $grey800;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 133.333% */
        letter-spacing: -0.24px;
        margin-bottom: 40px;
      }

      > .go-next {
        max-width: 580px;
        width: 580px;
        height: 60px;
        font-size: 20px;
        border-radius: 50px;
        margin: 0 auto;
        font-weight: 700;
      }

      > .video-box {
        margin-bottom: 32px;

        #intro-video {
          // display: none;
          width: 100%;
        }
      }

      > .submit {
        max-width: 460px;
        width: 100%;
        height: 56px;
        border-radius: 32px;
        margin: 0 auto;
        // font-size: 16px;
      }
    }
  }
  &.last {
    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 50px;
      width: 1064px;
      padding-bottom: 100px;

      > .container-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 100%;

        > .title-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          align-items: center;
          margin-bottom: 30px;

          > .title {
            font-weight: 500;
            font-size: 50px;
            font-style: normal;
            font-weight: 500;
            line-height: 74%; /* 37px */
            letter-spacing: -1px;
            color: $grey900;
          }

          > .desc {
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px; /* 183.333% */
            color: $grey900;
          }
        }

        > .experience {
          width: 100%;

          > .experience-title {
            text-align: center;
            color: $grey900;
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 33px; /* 103.125% */
            margin-bottom: 50px;
          }

          > .experience-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 30px;
            margin-bottom: 34px;

            > .experience-buttons-button {
              flex: 1;
              cursor: pointer;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              padding: 0 30px;

              &.selected {
                > .button-content-box {
                  .button-icon-box {
                    background-color: $grey900;

                    svg {
                      path {
                        fill: $white;
                      }
                    }
                  }
                }
              }

              > .button-content-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;

                .button-icon-box {
                  padding: 12px 28px;
                  background-color: $white;
                  border-radius: 10px;

                  > svg {
                    width: 40px;
                    height: 40px;
                  }
                }

                .button-title-box {
                  color: $grey800;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }

          > .experience-text-field {
            margin-bottom: 10px;
          }
        }

        > .survey {
          margin-bottom: 32px;
          width: 100%;

          > .survey-description {
            line-height: 23.2px;
            color: $grey900;
            padding: 24px;
            background-color: $grey50;
            border-radius: 16px;
            margin-bottom: 32px;
            word-break: keep-all;
            word-wrap: break-word;
          }

          > .survey-inputs-check {
            margin-bottom: 32px;

            > label.fs18 {
              color: $grey900;
              font-weight: 500;
            }
          }

          > .survey-inputs {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            > * {
              flex: 1;
            }

            > .survey-inputs-right {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              position: relative;

              > * {
                &:nth-child(1) {
                  flex: 1;
                }

                &:nth-child(2) {
                  flex: 2;
                }
              }

              .currency-absolute {
                position: absolute;
                z-index: 10;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
                color: $grey500;
              }
            }

            &:not(&:last-child) {
              margin-bottom: 10px;
            }
          }
        }

        > button.submit {
          max-width: 580px;
          width: 580px;
          height: 60px;
          font-size: 20px;
          border-radius: 50px;
          margin: 0 auto;
          font-weight: 700;
        }
      }
    }
  }
}

// 테스트 시작 페이지
.demo-pages-protected-evaluation-test-start {
  padding: 40px !important;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      width: 100%;

      > .title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;

        > .title-main {
          font-size: 60px;
          font-style: normal;
          font-weight: 500;
          line-height: 74%; /* 59.2px */
          letter-spacing: -3px;
        }
      }

      > .desc {
        margin-bottom: 34px;
        text-align: center;

        > p {
          color: $grey900;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 32px; /* 133.333% */
          letter-spacing: -0.24px;
          word-break: keep-all;
          word-wrap: break-word;
        }
      }

      > .alert {
        margin-bottom: 70px;

        > p {
          color: $grey800;
          font-size: 26px;
          font-style: normal;
          font-weight: 400;
          line-height: 44px; /* 60% */
          letter-spacing: -0.4px;

          > span {
            color: $grey800;
            text-decoration-line: underline;
          }
        }
      }

      > button.skip {
        cursor: pointer;
        max-width: 580px;
        width: 580px;
        height: 80px;
        font-size: 20px;
        border-radius: 50px;
        margin: 0 auto;
        font-weight: 700;
      }
    }
  }
}

// 스타일링 주관식
.demo-styling-pages-protected-evaluation-subjectives-essay {
  padding: 0 !important;

  &.not-styling {
    > .container {
      > .spliter {
        > .spliter-left {
          padding: 60px 45px;
        }
      }
    }
  }

  > .container {
    height: calc(100vh - 104px - 80px);
    width: 100%;

    > .spliter {
      width: 100%;
      height: 100%;
      display: flex;

      > .spliter-left {
        flex: 1;
        width: 40%;
        max-width: 740px;
        padding: 34px 45px;
        overflow-y: auto;

        > .question {
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          gap: 20px;

          p,
          pre {
            color: #1a1a1a;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 152.381% */
            letter-spacing: -0.5px;
            word-break: keep-all;
            word-wrap: break-word;
            overflow-y: auto;
          }

          > .styling-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: 74%; /* 20.72px */
              letter-spacing: -1px;
            }
          }

          > .style-image-box {
            height: auto;
            border: 2px solid #eeeeee;
            border-radius: 10px;
            overflow: hidden;

            > .style-image {
              display: block;
              width: 100%;
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: calc(100% - 740px);
        padding: 60px;

        .tox-tinymce {
          height: 100% !important;
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// 객관식 테스트
.demo-pages-protected-evaluation-objectives-common {
  padding: 0 40px !important;
  background-color: $grey150;

  > .container {
    max-width: 1590px;
    width: 100%;
    padding: 67px 0;
    gap: 66px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: calc(
      100vh - 104px - 80px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    > .objectives-title-box {
      h3 {
        font-size: 50px;
        font-style: normal;
        font-weight: 500;
        line-height: 74%; /* 37px */
        letter-spacing: -3px;
      }
    }

    > .separater {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
      gap: 90px;

      > .separater-left {
        flex: 1;
        height: calc(100vh - 104px - 80px - 167px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 16px;

        > .question {
          p,
          pre {
            color: #1a1a1a;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 152.381% */
            letter-spacing: -0.5px;
            word-break: keep-all;
            word-wrap: break-word;
            overflow-y: auto;
          }
        }
      }

      > .separater-right {
        flex: 1;
        height: calc(100vh - 104px - 80px - 167px);
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 60px;

        > p {
          color: $grey900;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 44px; /* 183.333% */
          letter-spacing: -1px;
        }

        > .label {
          color: $grey900;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -1px;
          line-height: 120%; /* 137.5% */
        }

        > .answer {
          > ul.answer-list {
            > li.answer-list-item {
              cursor: pointer;

              &:not(&:last-child) {
                margin-bottom: 38px;
              }

              > .container {
                display: flex;
                align-items: center;
                padding: 0 !important;
                border-radius: 4px;

                .radio-button {
                  padding: 0;
                  padding-right: 37px;
                }

                > p {
                  color: $grey900;
                  word-break: keep-all;
                  word-wrap: break-word;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 100%; /* 14px */
                }
                .MuiMenuItem-root {
                  padding: 0 !important;
                }
                > .hasImage {
                  width: 100%;
                  text-align: center;
                  > img {
                    max-width: 100%;
                    max-height: 160px;
                  }
                }
              }

              &.selected {
                > .container {
                  > .radio-button {
                    svg {
                      color: $grey800;
                      > path {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.pages-protected-evaluation-objectives-common-listening {
        .separater-left {
          display: flex;
          flex-direction: column;
          gap: 66px;

          .question-image {
            margin-bottom: 8px;
            text-align: center;

            img {
              max-width: auto;
              max-height: 240px;
            }
          }

          .button-wrapper {
            display: flex;

            button {
              display: inline-block;
              vertical-align: middle;
              padding: 20px;
              border-radius: 32px;
              font-weight: 500;
              height: 52px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 75%; /* 12px */
              letter-spacing: -0.32px;
            }

            .information {
              color: $grey600;
            }
          }
        }

        .answer {
          .question-title {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 44px; /* 183.333% */
            margin-bottom: 30px;
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// 언어 선택 페이지
.demo-pages-protected-evaluation-language-test {
  padding: 0 !important;
  background-color: $grey150;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    gap: 40px;
    width: 660px;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 32px;
      width: 100%;

      > .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 30px;
        width: 100%;

        > .title-main {
          color: $grey900;
          font-size: 50px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 37px */
          letter-spacing: -3px;
        }

        > .title-sub {
          p {
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 133.333% */
            letter-spacing: -0.4px;
          }
        }
      }

      > .select-container {
        width: 100%;
        padding: 0 30px;

        .select-wrapper {
          fieldset {
            width: 100%;
          }

          .check-button {
            padding: 16px;
            margin: 0;
            border: 1px solid $grey200;
            transition: all 0.1s;
            font-size: 16px;
            font-weight: 500;
            border-radius: 4px;
            overflow: hidden;
            align-items: center;
            background-color: $white;

            .MuiCheckbox-root {
              padding: 0;
              padding-right: 16px;
            }

            &.selected {
              border: 1px solid $grey300;
              background-color: $grey200;

              .Mui-checked {
                .MuiSvgIcon-root {
                  fill: $grey800 !important;
                }
              }

              .Mui-disabled {
                .MuiSvgIcon-root {
                  fill: $grey800 !important;
                }
              }

              &:hover {
                border-color: $grey300;
              }
            }

            &:not(:last-child) {
              margin-bottom: 16px;
            }
            &:hover {
              border-color: $grey500;
            }
          }
        }
      }

      .button-container {
        display: flex;
        width: 100%;
        gap: 20px;
        > button.start {
          flex: 1;
          display: inline-block;
          height: 70px;
          width: 100%;
          font-size: 20px;
          font-style: normal;
          font-weight: 700;
          line-height: 75%;
          letter-spacing: -0.4px;
          border-radius: 50px;
        }
      }
    }
  }
}

// 파입 업로드 테스트
.demo-pages-protected-evaluation-subjectives-file-upload {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 30px;
    max-width: 1590px;
    width: 100%;
    border-radius: 8px;
    height: calc(
      100vh - 120px - 99px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/

    &.more-width {
      width: 100%;
    }

    > .file-upload-title-box {
      h3 {
        color: $grey900;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.56px;
      }
    }

    > .container-inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
      width: 100%;
      > .separater {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 40px;

        > .separater-left {
          flex: 1;
          width: calc(50% - 20px);
          height: calc(
            100vh - 120px - 99px - 80px
          ); /*전체 박스에서 상하단 padding제외*/
          overflow-y: auto;
          padding-right: 20px;

          > .question {
            // font-size: 18px;
            // font-weight: bold;
            // line-height: 23.2px;
            word-break: keep-all;
            word-wrap: break-word;
            margin-bottom: 8px;
            overflow-y: auto;
            // background-color: $grey50;
            // padding: 20px;

            > * {
              all: revert;
              img {
                margin-top: 16px;
                max-width: 100%;
                height: auto;
                margin-top: 16px;
                width: 100%;
              }
            }

            > pre {
              white-space: pre-wrap;
              margin: 0;
              margin-bottom: 8px;
              line-height: 1.8 !important;
              // text-indent: 10px;

              * {
                all: revert;
                margin: 0 !important;
              }
            }

            > .timer {
              // font-size: 18px;
              margin-bottom: 36px;

              &::before {
                content: url('/assets/images/icon/timer.svg');
                margin-right: 7px;
                position: relative;
                top: 4px;
              }
            }

            > .video-box {
              margin-bottom: 32px;
              .question-video {
                display: block;
                width: 100%;
              }
            }
          }
        }

        > .separater-right {
          flex: 1;
          width: calc(50% - 20px);
          height: calc(
            100vh - 120px - 99px - 80px
          ); /*전체 박스에서 상하단 padding제외*/
          overflow-y: auto;
          padding-right: 20px;

          > .answer {
            width: 100%;
            height: 200px;
            border-radius: 16px;
            background-color: $grey100;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              background-color: rgba($grey100, 0.6);
            }

            &:active {
              background-color: rgba($grey100, 0.8);
            }

            > .file-upload-icon {
              margin-bottom: 16px;
            }

            > .file-upload-name {
              margin-top: 8px;
            }
          }
        }
      }

      > button.submit {
        width: 460px;
        height: 56px;
        border-radius: 32px;
      }
    }
  }
}
