@import '../include.scss';

.components-header-auth-header {
  > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: white;

    > .logo {
      width: 160px;
      height: 64px;
      align-items: center;
      text-align: center;
      display: flex;
      // background-image: url('/assets/images/logo/main_logo.svg');
      > img {
        max-width: 160px;
        max-height: 64px;
        // width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}

.components-header-app-header {
  > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    background-color: white;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);

    > .logo {
      width: 160px;
      height: 64px;
      align-items: center;
      text-align: center;
      display: flex;
      // background-image: url('/assets/images/logo/main_logo.svg');
      > img {
        max-width: 160px;
        max-height: 64px;
        // width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}

.components-header-testtaker-header {
  width: 100%;
  z-index: 1000;
  > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0 40px;
    background-color: white;

    > .logo {
      width: 160px;
      height: 64px;
      align-items: center;
      text-align: center;
      display: flex;
      // background-image: url('/assets/images/logo/main_logo.svg');
      > img {
        max-width: 160px;
        max-height: 64px;
        // width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
    > .right {
      display: flex;
      align-items: flex-start;
      padding: 0px;
      gap: 4px;

      > .progress {
        width: 460px;
        > .progress-timer {
        }

        > .progress-count {
          > .progress-count-time {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 5px;

            &::before {
              content: url('/assets/images/icon/testtaker/demo-timer.svg');
              margin-right: 8px;
            }

            > span {
              width: 60px;
              text-align: center;
              // font-size: 16px;
              font-weight: bold;
            }

            > .progress-bar {
              position: relative;
              width: 380px;
              height: 6px;
              border-radius: 4px;
              background-color: $grey100;
              margin-left: 8px;

              > .progress-bar-fill {
                height: 100%;
                background-color: $grey900;
                border-radius: 4px;
                transition: width 1s linear;
              }
            }
          }

          > .progress-count-cnt {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &::before {
              content: url('/assets/images/icon/testtaker/demo-count.svg');
              margin-right: 8px;
              position: relative;
              top: 1px;
            }

            > span {
              text-align: center;
              width: 60px;
              font-weight: bold;
            }

            > .progress-bar {
              position: relative;
              width: 380px;
              height: 6px;
              border-radius: 4px;
              background-color: $grey100;
              margin-left: 8px;

              > .progress-bar-fill {
                height: 100%;
                background-color: $grey900;
                border-radius: 4px;
                transition: width 1s;
              }
            }
          }
        }
      }
      > button {
        margin-left: 32px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 24px;
        height: 56px;
        border-radius: 32px;
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        letter-spacing: -0.16px;

        &.false {
          cursor: auto;
          background: $grey300;
        }
        &.active {
          cursor: pointer;
          background-color: $grey900;
          color: white;

          &:hover {
            background-color: rgba($grey900, 0.6);
          }

          &:active {
            background-color: rgba($grey900, 0.8);
          }
        }

        &::after {
          content: url('/assets/images/icon/arrow-right-white.svg');
          margin-left: 11px;
          position: relative;
          top: 2px;
        }
      }
    }
  }
}
