@import './include.scss';
@import './reset.scss';

@import './pc/header.scss' screen and (min-width: 1024px);
@import './pc/footer.scss' screen and (min-width: 1024px);
@import './pc/auth.scss' screen and (min-width: 1024px);
@import './pc/testtaker.scss' screen and (min-width: 1024px);

@import './mobile/header.scss' screen and (max-width: 1023px);
@import './mobile/footer.scss' screen and (max-width: 1023px);
@import './mobile/auth.scss' screen and (max-width: 1023px);
@import './mobile/testtaker.scss' screen and (max-width: 1023px);

$mobile: 'only screen and (max-width: 1023px)';
$w448px: 'only screen and (max-width: 448px)';
$pc: 'screen and (min-width: 1024px)';
$pcSmall: 'only screen and (max-width: 1440px)';

#root {
  background-color: $grey50;
  display: flex;
  flex-direction: column;

  > header {
  }

  @media #{$pc} {
    > main {
      flex: 1;
      padding: 40px 40px 0 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    --toastify-toast-bottom: 40px;
    --toastify-toast-max-width: 448px;
  }
  @media #{$mobile} {
    > main {
      flex: 1;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    --toastify-toast-bottom: 24px;
    --toastify-toast-max-width: 448px;
  }
  @media #{$w448px} {
    --toastify-toast-max-width: 100vw;
  }

  > footer {
  }
}

.hidden {
  visibility: hidden;
}
.text-button {
  &.primary {
    color: $blue500;

    &:hover {
      color: $primary_color_hover;
    }

    &:active {
      color: $primary_color_active;
    }
  }

  &.secondary {
    color: $pink600;

    &:hover {
      color: $secondary_color_hover;
    }

    &:active {
      color: $secondary_color_active;
    }
  }

  &.tertiary {
    color: $tertiary_color;

    &:hover {
      color: $tertiary_color_hover;
    }

    &:active {
      color: $tertiary_color_active;
    }
  }
}
.common-button {
  // font-size: 16px;

  &.primary {
    cursor: default;
    background-color: $grey100;
    color: $grey500;

    &.active {
      cursor: pointer;
      background-color: $primary_color;
      color: white;

      &:hover {
        background-color: rgba($primary_color, 0.6);
      }

      &:active {
        background-color: rgba($primary_color, 0.8);
      }
    }
  }

  &.secondary {
    cursor: default;
    background-color: $grey100;
    color: $grey500;

    &.active {
      cursor: pointer;
      background-color: $secondary_color;
      color: white;

      &:hover {
        background-color: rgba($secondary_color, 0.6);
      }

      &:active {
        background-color: rgba($secondary_color, 0.8);
      }
    }
  }

  &.gray,
  &.grey {
    cursor: default;
    background-color: $grey100;
    color: $grey500;

    &.active {
      cursor: pointer;
      background-color: $grey100;
      color: $grey500;

      &:hover {
        background-color: rgba($grey100, 0.6);
      }

      &:active {
        background-color: rgba($grey100, 0.8);
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.6;
    }
  }

  &.black {
    cursor: default;
    background-color: $grey900;
    color: $white;

    &.active {
      cursor: pointer;
      background-color: $black;
      color: $white;

      &:hover {
        background-color: rgba($grey900, 0.6);
      }

      &:active {
        background-color: rgba($black, 0.8);
      }
    }

    &.disabled {
      cursor: default;
      background-color: $grey300;
    }
  }

  &.white {
    cursor: default;
    background-color: $white;
    color: $grey900;
    border: 1px solid $grey300;

    &.active {
      cursor: pointer;
      background-color: $white;
      color: $grey900;

      &:hover {
        background-color: rgba($grey100, 0.6);
      }

      &:active {
        background-color: rgba($grey100, 0.8);
      }
    }

    &.disabled {
      cursor: default;
      opacity: 0.6;
    }
  }
}

.modal {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;

  &.invite-email-sent {
    width: 312px;
    height: 188px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .content {
      height: calc(100% - 56px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > .title {
        > .title-main {
          font-weight: bold;
          margin-bottom: 4px;
          text-align: center;
        }

        > .title-sub {
          font-size: 14px;
          color: $grey500;
          text-align: center;
        }
      }
    }

    > .buttons {
      width: 100%;

      > .confirm {
        width: 100%;
        height: 56px;
      }
    }
  }

  &.media-authority-modal {
    width: 312px;
    height: 188px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .content {
      height: calc(100% - 56px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px;

      > .title {
        > .title-main {
          font-weight: bold;
          margin-bottom: 10px;
          text-align: center;
          line-height: 1.2;
          word-break: keep-all;
        }

        > .title-sub {
          font-size: 14px;
          color: $grey500;
          text-align: center;
          line-height: 1.2;
        }
      }
    }

    > .buttons {
      width: 100%;

      > .confirm {
        width: 100%;
        height: 56px;
      }
    }
  }

  &.gender-change-modal {
    width: 312px;
    height: 188px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .content {
      height: calc(100% - 56px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 8px;

      > .title {
        > .title-main {
          font-weight: bold;
          margin-bottom: 10px;
          text-align: center;
          line-height: 1.2;
          word-break: keep-all;
        }

        > .title-sub {
          font-size: 14px;
          color: $grey500;
          text-align: center;
          line-height: 1.2;
        }
      }
    }

    > .buttons {
      width: 100%;

      > .confirm {
        width: 100%;
        height: 56px;
      }

      &.yes-or-no {
        display: flex;

        > .cancel {
          cursor: pointer;
          color: $grey900;
          width: 100%;
          height: 56px;
        }
      }
    }
  }

  &.circular-progress {
    width: 100px;
    height: 100px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  &.exit {
    width: 312px;
    height: 188px;

    > .content {
      width: 100%;
      height: calc(100% - 56px);
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;

      > .title {
        font-weight: bold;
        margin-bottom: 4px;
      }

      > .description {
        font-size: 14px;
        line-height: 20.3px;
        text-align: center;
        color: $grey500;
      }
    }

    > .buttons {
      display: flex;

      > button {
        flex: 1;
        height: 56px;
      }
    }
  }

  &.icon-modal {
    width: 272px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .content {
      height: calc(100% - 56px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 8px;

      > .title {
        > .title-main {
          font-weight: 500;
          margin-bottom: 10px;
          text-align: center;
          line-height: 1.2;
          word-break: keep-all;
          font-size: 16px;
        }

        > .title-sub {
          font-size: 14px;
          color: $grey600;
          text-align: center;
          line-height: 1.2;
          font-weight: 500;
          word-break: keep-all;
        }
      }
    }

    > .buttons {
      width: 100%;

      > .confirm {
        width: 100%;
        height: 56px;
      }
    }
  }

  &.media-setting-modal {
    width: 460px;
    height: auto;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .content {
      // height: calc(100% - 56px);
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      overflow: auto;
      width: 100%;

      > .header-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        width: 100%;
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 3;

        > .title-main {
          font-weight: 700;
          text-align: center;
          line-height: 1.2;
          word-break: keep-all;
          font-size: 16px;
        }
      }

      > .body-contents {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px;

        > article {
          > h2 {
            font-weight: 700;
            margin-bottom: 8px;
          }
        }

        .body-contents-mic {
          .mic-canvas {
            margin-top: 8px;
            height: 4px;
            width: 100%;
            border-radius: 4px;
          }
        }

        .body-contents-video {
          background-color: $black;
          font-size: 0;
          text-align: center;
          video {
            max-width: 100%;
            height: 230px;
            transform: rotateY(180deg);
          }
        }

        > .alert {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          gap: 8px;
          width: 100%;
          background: $grey50;
          border-radius: 16px;
          > .alert-title {
            // font-size: 18px;
            font-weight: 700;
          }

          > ul.alert-list {
            > li.alert-list-item {
              position: relative;
              padding-left: 20px;
              line-height: 23.2px;
              word-break: keep-all;
              word-wrap: break-word;

              &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 8px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $grey900;
              }
            }
          }
        }

        > .buttons {
          width: 100%;

          > .confirm {
            width: 100%;
            height: 56px;
            border-radius: 32px;
          }
        }
      }
    }
  }

  &.media-test-modal {
    width: 460px;
    height: auto;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      width: 100%;
      padding: 32px;

      > .header-title {
        text-align: center;
        width: 100%;
        background-color: $white;
        z-index: 3;
        margin-bottom: 24px;

        > .title-main {
          font-weight: 700;
          text-align: center;
        }
      }

      > .body-contents {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;

        .contents-item {
          display: flex;
          gap: 16px;
          padding: 16px;
          background-color: $grey50;
          border-radius: 8px;
          align-items: center;

          aside {
            .desc-title {
              font-weight: 700;
            }

            .desc-p {
              color: $grey600;
              word-break: keep-all;
            }
          }
        }

        > .buttons {
          width: 100%;

          > .confirm {
            width: 100%;
            height: 56px;
            border-radius: 32px;

            &.black {
              background-color: $grey900;

              &:hover {
                background-color: $black;
              }
            }
          }
        }
      }
    }
  }

  &.result-modal {
    width: 272px;
    min-height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    > .content {
      height: calc(100% - 56px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 16px;
      gap: 8px;

      > .title {
        > .title-main {
          font-weight: 500;
          margin-bottom: 10px;
          text-align: center;
          line-height: 1.2;
          word-break: keep-all;
          font-size: 16px;
        }

        > .title-sub {
          font-size: 24px;
          color: $grey900;
          text-align: center;
          line-height: 1.2;
          font-weight: 700;
          word-break: keep-all;
        }
      }
    }

    > .buttons {
      width: 100%;

      > .confirm {
        width: 100%;
        height: 56px;
      }
    }
  }

  &.demo-media-setting-modal {
    width: 730px;
    height: auto;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 !important;

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      width: 100%;
      background-color: $grey150;

      > .header-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 40px;
        width: 100%;
        position: sticky;
        top: 0;
        background-color: $white;
        z-index: 3;

        > .title-main {
          font-weight: 700;
          text-align: center;
          line-height: 1.2;
          word-break: keep-all;
          font-size: 16px;
        }
      }

      > .body-contents {
        margin: 20px 0 0 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 24px;
        background-color: white;

        .setting-title {
          color: $grey900;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 130%;
          letter-spacing: -0.24px;
          margin-bottom: 8px;
        }

        .setting-desc {
          color: $grey900;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 145%;
          letter-spacing: -0.14px;
        }
        > article {
          > h2 {
            color: $grey900;
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 145%;
            letter-spacing: -0.16px;
            margin-bottom: 8px;
          }
        }

        .body-contents-mic {
          .mic-canvas {
            margin-top: 8px;
            height: 4px;
            width: 100%;
            border-radius: 4px;
          }
        }

        .body-contents-video {
          background-color: $black;
          font-size: 0;
          text-align: center;
          video {
            max-width: 100%;
            height: 230px;
            transform: rotateY(180deg);
          }
        }

        > .alert {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          gap: 8px;
          width: 100%;
          background: $grey50;
          border-radius: 8px;
          > .alert-title {
            color: $grey900;
            font-weight: 700;
            line-height: 145%;
            letter-spacing: -0.18px;
          }

          > ul.alert-list {
            color: $grey800;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.14px;
            > li.alert-list-item {
              position: relative;
              padding-left: 20px;
              line-height: 23.2px;
              word-break: keep-all;
              word-wrap: break-word;
              margin-bottom: 16px;

              &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 8px;
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $grey900;
              }
            }
          }
        }

        > .buttons {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          > .confirm {
            width: 100%;
            color: white;
            max-width: 310px;
            height: 56px;
            background-color: $grey900;
            border-radius: 32px;
          }
        }
      }

      .footer {
        color: $grey500;
        display: flex;
        background: $grey150;
        width: 100%;
        height: 60px;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media #{$mobile} {
    &.media-test-modal {
      max-width: 400px;
      width: calc(100% - 48px);
      height: auto;
      max-height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        width: 100%;
        padding: 32px 16px;

        > .header-title {
          text-align: center;
          width: 100%;
          background-color: $white;
          z-index: 3;
          margin-bottom: 24px;

          > .title-main {
            font-weight: 700;
            text-align: center;
            font-size: 18px !important;
          }
        }

        > .body-contents {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .contents-item {
            display: flex;
            gap: 16px;
            padding: 16px 12px;
            background-color: $grey50;
            border-radius: 8px;
            align-items: center;

            aside {
              &.icon {
                max-width: 30px;
              }

              .desc-title {
                font-weight: 700;
                font-size: 16px !important;
                margin-bottom: 4px;
                word-break: keep-all;
              }

              .desc-p {
                color: $grey600;
                word-break: keep-all;
                font-size: 12px !important;
                word-break: keep-all;
              }
            }
          }

          > .buttons {
            width: 100%;

            > .confirm {
              width: 100%;
              height: 56px;
              border-radius: 32px;
              font-weight: 700;

              &.black {
                background-color: $grey900;

                &:hover {
                  background-color: $black;
                }
              }
            }
          }
        }
      }
    }

    &.demo-media-setting-modal {
      max-width: 400px;
      width: 100%;
      max-height: 100vh;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      border-radius: 0 !important;

      > .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: auto;
        width: 100%;
        height: 100%;
        background-color: $grey150;

        > .header-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 16px 24px;
          width: 100%;
          position: sticky;
          top: 0;
          background-color: $white;
          z-index: 3;

          > .title-close-button {
            font-size: 0;
          }

          > .title-main {
            font-weight: 700;
            text-align: center;
            line-height: 1.2;
            word-break: keep-all;
            font-size: 16px;
          }
        }

        > .body-contents {
          margin: 32px 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 32px;
          padding: 24px;
          background-color: white;

          .setting-title {
            color: $grey900;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            letter-spacing: -0.24px;
            margin-bottom: 8px;
          }

          .setting-desc {
            color: $grey900;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%;
            letter-spacing: -0.14px;
          }
          > article {
            > h2 {
              color: $grey900;
              font-family: Pretendard;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 145%;
              letter-spacing: -0.16px;
              margin-bottom: 8px;
            }
          }

          .body-contents-mic {
            .mic-canvas {
              margin-top: 8px;
              height: 4px;
              width: 100%;
              border-radius: 4px;
            }
          }

          .body-contents-video {
            background-color: $black;
            font-size: 0;
            text-align: center;
            video {
              max-width: 100%;
              height: 230px;
              transform: rotateY(180deg);
            }
          }

          > .alert {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 16px;
            gap: 8px;
            width: 100%;
            background: $grey50;
            border-radius: 16px;

            > .alert-title {
              color: $grey900;
              font-weight: 700;
              line-height: 145%;
              letter-spacing: -0.18px;
            }

            > ul.alert-list {
              color: $grey800;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.14px;

              > li.alert-list-item {
                position: relative;
                padding-left: 20px;
                line-height: 145%;
                font-weight: 400;
                word-break: keep-all;
                word-wrap: break-word;
                margin-bottom: 0;

                &:not(:last-child) {
                  margin-bottom: 10px;
                }

                &::before {
                  content: '';
                  position: absolute;
                  top: 9px;
                  left: 8px;
                  width: 3px;
                  height: 3px;
                  border-radius: 50%;
                  background-color: $grey900;
                }
              }
            }
          }

          > .buttons {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            > .confirm {
              width: 100%;
              color: white;
              max-width: 100%;
              font-weight: 700;
              height: 56px;
              background-color: $grey900;
              border-radius: 32px;
            }
          }
        }

        .footer {
          display: none !important;
          color: $grey500;
          display: flex;
          background: $grey150;
          width: 100%;
          height: 60px;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.dark-modal {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  max-width: 460px;
  padding: 32px;
  border-radius: 8px;

  .dark-modal-container {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .dark-modal-title-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;

      .main-title {
        color: $grey900;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 28px */
        letter-spacing: -0.2px;
      }

      .sub-title {
        white-space: pre-wrap;
        text-align: center;
        color: $grey600;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 145%; /* 20.3px */
        letter-spacing: -0.14px;
      }
    }

    .dark-modal-button-wrapper {
      .flex {
        display: flex;
        align-items: center;
        gap: 8px;

        > * {
          flex: 1;
        }

        .dark-modal-submit,
        .dark-modal-close {
          font-weight: 700;
        }
      }
    }
  }

  @media #{$mobile} {
    width: calc(100% - 48px);
    padding: 32px 16px;

    .dark-modal-container {
      .dark-modal-title-wrapper {
        .main-title {
          font-size: 18px;
        }
      }
    }
  }
}

@media #{$mobile} {
  .toast-modal {
    max-width: 300px !important;
  }
}

.toast-modal {
  position: fixed;
  z-index: 1001;
  top: 28px;
  left: 0;
  max-width: 400px;
  display: flex;
  align-items: center;
  background-color: $white;
  box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: left 0.3s ease-in-out;

  &.show {
    left: 24px;
  }

  &.hide {
    left: -120%;
  }

  > .content {
    max-width: 328;
    padding: 16px 24px;

    .title {
      .title-main {
        font-weight: 700;
        margin-bottom: 4px;
      }

      .title-sub {
        color: $grey700;
        font-weight: 500;
      }
    }
  }

  > .buttons {
    max-width: 72px;
    padding: 39px 24px;
    font-size: 0;

    button {
      font-size: 0;

      svg {
        path {
          fill: $blue500;
        }
      }
    }
  }
}

.common-checkbox {
  display: inline-flex;
  align-items: center;

  &::before {
    content: '';
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 2px solid #000;
    margin-right: 6px;
  }

  &.checked::before {
    content: '';
    width: 20px;
    height: 20px;
    background-image: url('/assets/images/icon/checkbox.svg');
    background-size: contain;
    border: none;
  }
  &::before {
  }
}

.Toastify__toast-container {
  bottom: var(--toastify-toast-bottom) !important;
  width: 100% !important;
  max-width: calc(var(--toastify-toast-max-width) - 48px);
  left: 50% !important;
  transform: translateX(-50%) !important;
  > .Toastify__toast {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 24px;
    position: relative;
    max-width: 400px;
    min-height: 72px;
    margin-bottom: 1em;
    background: $white;
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    > .Toastify__toast-body {
      padding: 0px;
      > div {
        max-width: 280px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
        color: $grey900;
        word-break: keep-all;
        word-wrap: break-word;
      }
    }
  }

  .toast-close-button {
    width: 24px;
    height: 24px;
    border: none;
    min-width: 24px;
    > svg > path {
      fill: $blue500;
    }
    &:hover {
      > svg > path {
        fill: $blue700;
      }
    }
  }
}

.dropdown-container {
  position: relative;

  .dropdown-toggle-button {
    display: flex;
    align-items: center;
    padding: 6px 8px;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    line-height: 145%;
    letter-spacing: -0.01em;
    color: $grey900;
    border: 1px solid $grey300;
    border-radius: 4px;
    width: 100%;
    transition: all 0.2s;

    > span {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &:hover {
      border-color: $grey700;
    }
    &:focus {
      border-color: $blue500;
      box-shadow: 0px 0px 0px 3px rgba(50, 148, 238, 0.25);
    }
    &:disabled {
      * {
        opacity: 0.6;
        color: $grey500;
      }
    }
  }

  .dropdown-list-box {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    z-index: 2;

    > .dropdown-list {
      padding: 8px 0;
      background-color: $white;
      overflow: hidden;
      width: 100%;
      border-radius: 4px;
      box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
        0px 1px 2px rgba(0, 0, 0, 0.3);

      > .dropdown-list-item {
        padding: 12px 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 145%;
        cursor: pointer;
        transition: background 0.2s;

        &:hover {
          background: $grey100;
        }
      }
    }
  }
}

// testtaker.scss 로 옮겨야 할 css
.demo-pages-protected-evaluation-subjectives-styling {
  padding: 0px !important;

  > .container {
    width: 1040px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(
      100vh - 104px - 80px
    ); /*전체 높이에서 헤더 영역 40px, 하단 영역 일단 99px로 잡아 설정*/
    border-radius: 8px;

    &.more-width {
      width: 100%;
      padding: 0;

      .tox-tinymce {
        height: 100% !important;
      }
    }

    > .spliter {
      width: 100%;
      display: flex;
      // gap: 10px;
      height: 100%;

      > .spliter-left {
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 34px 45px;
        gap: 48px;
        width: 740px;
        background-color: $grey50;

        > .question {
          width: 100%;
          min-height: 850px;
          // font-weight: bold;
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
          // overflow-y: auto;
          display: flex;
          flex-direction: column;
          gap: 10px;

          p,
          pre {
            color: #1a1a1a;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 152.381% */
            letter-spacing: -0.5px;
            word-break: keep-all;
            word-wrap: break-word;
            overflow-y: auto;
          }

          > .styling-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: 74%; /* 20.72px */
              letter-spacing: -1px;
            }

            .reset-button {
              svg {
                path {
                  fill: $white;
                }
              }

              &:disabled {
                color: $grey400;
                background-color: $grey300;

                svg {
                  path {
                    fill: $grey400;
                  }
                }
              }
            }
          }

          > .base {
            width: auto;
            height: 750px;
            display: flex;
            position: relative;
            background-color: $grey50;

            > .information-box {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              text-align: center;

              .icon-box {
                margin-bottom: 10px;
              }

              p {
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 70%; /* 14px */
                letter-spacing: -0.4px;
                color: $grey500;
              }
            }

            > .accessories-socks {
              position: absolute;
              max-width: 240px;
              width: 100%;
              height: auto;
              right: 0;
              bottom: 12px;
              z-index: 60;
              padding: 0 20px;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .outerwear {
              position: absolute;
              max-width: 360px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 5;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .shoes {
              position: absolute;
              width: 200px;
              height: 150px;
              left: 186px;
              bottom: 60px;
              z-index: 100;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .tops {
              position: absolute;
              width: 100%;
              max-width: 360px;
              height: auto;
              width: 100%;
              height: auto;
              left: 160px;
              top: 62px;
              z-index: 10;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.active {
                z-index: 10;
              }
            }
            > .bottoms {
              position: absolute;
              max-width: 360px;
              width: 100%;
              height: auto;
              right: 28px;
              bottom: 5px;
              z-index: 15;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.down {
                z-index: 5;
              }
            }
            > .dressess {
              position: absolute;
              max-width: 420px;
              width: 100%;
              height: auto;
              right: 70px;
              bottom: 103px;
              z-index: 10;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.down {
                z-index: 0;
              }
            }
            > .handbags {
              position: absolute;
              max-width: 240px;
              width: 100%;
              height: auto;
              left: 0;
              bottom: 0;
              z-index: 50;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-belt {
              position: absolute;
              max-width: 150px;
              width: 100%;
              height: auto;
              right: 170px;
              bottom: 180px;
              z-index: 51;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-ring {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 45;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-bracelet {
              position: absolute;
              max-width: 200px;
              width: 100%;
              height: auto;
              right: 10px;
              bottom: 278px;
              z-index: 110;
              padding: 0 40px;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-brooches {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 101;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-eyewear {
              position: absolute;
              max-width: 240px;
              width: 100%;
              height: auto;
              right: 26px;
              top: 0;
              z-index: 55;
              padding: 0 20px;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-hair {
              position: absolute;
              max-width: 320px;
              padding: 0 70px;
              width: 100%;
              height: auto;
              right: 0;
              top: 125px;
              z-index: 105;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }

              &.active {
                z-index: 102;
              }
            }
            > .accessories-watch {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 4;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-gloves {
              position: absolute;
              width: 180px;
              height: 180px;
              padding: 0 20px;
              left: 79px;
              bottom: 214px;
              z-index: 20;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
            > .accessories-tie {
              position: absolute;
              max-width: 560px;
              width: 100%;
              height: auto;
              left: 0;
              top: 0;
              z-index: 64;
              > img {
                width: 100%;
                height: auto;
                margin-top: 0px;
              }
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
          > .timer {
            margin-bottom: 36px;
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }
        }
      }
      > .spliter-center {
        padding: 32px 8px;
        border: 1px solid $grey200;

        .styling-tool-wrapper {
          .menu-box {
            li {
              padding: 4px;

              &:not(.hr) {
                cursor: pointer;
              }

              &:not(:last-child) {
                margin-bottom: 12px;
              }

              &.hr {
                margin-bottom: 8px;
                padding: 0;
              }

              hr {
                display: block;
                width: 18px;
                height: 1px;
                background-color: $grey200;
              }
            }
          }
        }
      }
      > .spliter-right {
        width: calc(100% - 740px);
        padding-top: 40px;
        padding-left: 20px;
        padding-right: 20px;
        overflow: hidden;

        > .contents {
          max-width: 760px;
          margin: 0 auto;

          > .tab {
            padding: 0px;
            overflow-x: auto;

            > .tabs {
              display: flex;
              justify-content: space-between;
              gap: 30px;

              > .item {
                flex-direction: row;
                padding: 8px 10px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 145%;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: $grey900;
                cursor: pointer;
                transition: background-color 0.2s;

                &:hover {
                  background-color: $grey50;
                }

                &.selected {
                  color: $grey800;
                  font-weight: 500;
                  border-bottom: $grey800 2px solid;
                }
                &.checked {
                  color: $grey800;
                  font-weight: 500;
                  border-bottom: $grey800 2px solid;
                }
              }

              @media #{$pcSmall} {
                &.tabs {
                  gap: 10px;
                }
              }
            }
          }
          > .itemlist {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 46px;
            gap: 24px;
            width: 100%;
            height: calc(100vh - 100px - 80px - 86px - 28px);
            overflow-y: auto;
            background: #ffffff;

            > .items {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              padding: 0px;
              gap: 16px;
              width: 100%;
              // height: 315px;
              > .title {
                width: 100%;
                height: 23px;
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 145%;
                display: flex;
                align-items: center;
                letter-spacing: -0.01em;
                color: #2e3338;
              }
              > .images {
                display: grid;
                align-items: flex-start;
                grid-template-columns: repeat(auto-fill, 180px);
                padding: 0px;
                gap: 12px;
                width: 100%;
                justify-content: flex-start;

                > .product {
                  box-sizing: border-box;
                  width: 180px;
                  height: 240px;
                  background: #e7e7e7;
                  border-radius: 8px;
                  display: flex;
                  position: relative;
                  border: 2px solid transparent;
                  cursor: pointer;

                  &:hover {
                    border: 2px solid $grey300;
                  }

                  > .check-icon {
                    position: absolute;
                    width: 26px;
                    height: 26px;
                    right: 14px;
                    top: 10px;
                    display: none;
                  }

                  &.selected {
                    background-color: #b9b9b9;
                    > .check-icon {
                      display: block;
                    }
                  }

                  > img {
                    width: 100%;
                    height: auto;
                  }
                }

                @media #{$pcSmall} {
                  &.images {
                    grid-template-columns: repeat(auto-fill, 140px);

                    > .product {
                      width: 140px;
                      height: 180px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}
