@import './include.scss';

html,
body,
#root {
  min-height: 100vh;
}

body {
  margin: 0;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif !important;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: Consolas, source-code-pro, Menlo, Monaco, 'Courier New',
    monospace !important;
}

// language dropdown component
.language-selector {
  position: relative;
  display: inline-block;

  > .language-selector-toggle {
    position: relative;
    width: 115px;
    height: 32px;
    border-radius: 8px;
    padding: 0 10px;
    border: 1px solid $grey300;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .language-info-wrapper {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      gap: 5px;
    }

    .language-arrow-wrapper {
      font-size: 0;

      svg {
        path {
          fill: $grey500;
        }
      }
    }

    &:hover,
    &.show {
      border: 1px solid $grey500;
    }

    &:active {
      border: 1px solid rgba($grey500, 0.6);
    }
  }

  > .language-selector-dropdown {
    position: absolute;
    top: -30px;
    right: -80px;
    background-color: $grey100;
    z-index: 1;
    border-radius: 8px;
    overflow: hidden;

    > ul.language-selector-dropdown-list {
      display: flex;
      flex-direction: column-reverse;
      > li.language-selector-dropdown-list-item {
        cursor: pointer;
        padding: 10px;
        color: $black;
        text-align: center;

        &:hover {
          background-color: $grey200;
        }
      }
    }
  }
}
