@import '../include.scss';

.components-footer-app-footer,
.components-footer-auth-footer {
  padding: 40px 0 20px 0;
  background-color: black;
  height: 132px;
  font-size: 14px;
  color: $grey500;
  text-align: center;

  // :first-child {
  //   margin-bottom: 4px;
  // }
  .language-selector {
    margin-bottom: 8px;

    * {
      color: $white;
      fill: $white !important;
    }

    > .language-selector-dropdown {
      top: -15px;
      right: -65px;
    }
  }
}
