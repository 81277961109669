@import '../include.scss';

.components-footer-app-footer,
.components-footer-auth-footer {
  padding: 40px 0 20px 0;
  background-color: black;
  height: 104px;
  font-size: 14px;
  color: $grey500;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;

  address {
    :first-child {
      text-align: center;
      margin-bottom: 4px;
    }
  }
}
