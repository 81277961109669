@import '../include.scss';

.fixed {
  position: fixed;
  top: 0;
}

/* for highlight */
pre {
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 150% !important;
  word-break: break-all;
  white-space: pre-wrap;
  &:not(&:last-child) {
    margin-bottom: 16px;
  }
}
code.hljs {
  padding: 24px !important;
}
.hljs {
  background: $grey900 !important;
  border-radius: 8px !important;
}

.codeBlock {
  width: 100%;
  > p {
    font-size: 18px;
    font-weight: bold;
    line-height: 23.2px;
    color: $grey900;
    margin-bottom: 8px;
    word-break: break-word;
    white-space: pre-wrap;
  }
}
.codeBlock-sub {
  width: 100%;
}

.pages-public-setup {
  > .container-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 32px;
    width: 100%;
    background: $white;
    margin-top: 32px;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 40px;
    width: 100%;

    > .container-left {
      width: 100%;
      flex: 1;

      > .header {
        margin-bottom: 32px;

        > .header-title {
          // font-size: 28px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        > .header-description {
          line-height: 23.2px;
          color: $grey900;
        }
      }

      > .camera,
      > .mic {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(&:last-child) {
          margin-bottom: 32px;
        }

        > .camera-left,
        > .mic-left {
          > .camera-left-title,
          > .mic-left-title {
            font-weight: bold;
            margin-bottom: 8.5px;
          }

          > .camera-left-title::before {
            content: url('/assets/images/icon/camera.svg');
            margin-right: 10px;
            position: relative;
            top: 1px;
          }

          > .mic-left-title::before {
            content: url('/assets/images/icon/mic.svg');
            margin-right: 10px;
            position: relative;
            top: 1px;
          }

          > .camera-left-selected,
          > .mic-left-selected {
            color: $grey900;
          }
        }

        > .camera-right,
        > .mic-right {
          position: relative;

          > .camera-right-setting,
          > .mic-right-setting {
            width: 56px;
            height: 56px;
            border-radius: 50%;

            &::after {
              content: url('/assets/images/icon/setting.svg');
            }
          }

          > .camera-right-dropdown,
          > .mic-right-dropdown {
            position: absolute;
            top: 61px;
            right: 28px;
            z-index: 10;

            > ul {
              padding: 8px 0;
              box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15),
                0px 1px 2px rgba(0, 0, 0, 0.3);
              border-radius: 4px;
              background-color: white;

              > li {
                cursor: pointer;
                padding: 0 16px;
                width: 270px;
                height: 48px;
                display: inline-flex;
                align-items: center;

                &:hover {
                  background-color: $grey100;
                }
              }
            }
          }
        }
      }
    }

    > .container-right {
      width: 100%;
      flex: 1;

      > .video {
        width: 100%;
        margin-bottom: 32px;
        transform: rotateY(180deg);

        > .video-display {
          width: 100%;
          height: 100%;
        }
      }

      > .alert {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 16px;
        gap: 8px;
        width: 100%;
        background: $grey50;
        > .alert-title {
          // font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        > ul.alert-list {
          > li.alert-list-item {
            position: relative;
            padding-left: 10px;
            line-height: 23.2px;
            word-break: keep-all;
            word-wrap: break-word;

            &:not(&:last-child) {
              margin-bottom: 4px;
            }

            &::before {
              content: '';
              position: absolute;
              top: 8px;
              left: 0px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $grey900;
            }
          }
        }
      }
    }
  }

  .submit {
    width: 100%;
    height: 56px;
    border-radius: 32px;
  }
}

.pages-public-selecttest {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    margin-top: 32px;
    gap: 10px;
    width: 100%;
    background: $white;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;
      width: 100%;
      > .header {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        height: 93px;

        > .header-title {
          font-weight: 700;
          color: $grey900;
        }

        > .header-description {
          color: $grey900;
          line-height: 23.2px;
          > span {
            color: $blue500;
          }
        }
      }

      > .test-select {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 64px;
        width: 100%;
        > ul.test-list {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 16px;
          width: 100%;

          > li.test-list-item {
            border: 1px solid $grey100;
            border-radius: 4px;
            width: 100%;

            &:hover {
              background: $grey50;
            }

            > .container {
              display: flex;
              align-items: center;
              width: 100%;
              padding: 4px;
              border-radius: 4px;
              > p {
                // word-break: break-all;
                word-break: keep-all;
                word-wrap: break-word;
              }
            }

            &.selected {
              > .container {
                background-color: $primary_color;

                > .radio-button {
                  svg {
                    color: white;
                    > path {
                    }
                  }
                }

                > p {
                  color: white;
                }
              }
            }
          }
        }
      }
      > .submit {
        margin-top: 32px;
        width: 100%;
        > button {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 18px 24px 19px;
          gap: 8px;
          width: 100%;
          height: 60px;
          border-radius: 32px;
          &::after {
            width: 12px;
            height: 12px;
            display: inline-block;
            content: '';
            -webkit-mask: url('/assets/images/icon/arrow-right-white.svg')
              no-repeat 50% 50%;
            mask: url('/assets/images/icon/arrow-right-white.svg') no-repeat 50%
              50%;
            -webkit-mask-size: cover;
            mask-size: cover;
            background-color: $grey500;
          }
          &.active::after {
            background-color: $white;
          }
        }
      }
    }
  }
}

.pages-protected-evaluation-test-start {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 40px;
    width: 100%;
    background: $white;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 32px;

      width: 100%;
      > .title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;

        > .title-sub {
        }

        > .title-main {
          // font-size: 28px;
          font-weight: bold;
        }
      }

      > .desc {
        width: 100%;
        padding-bottom: 32px;
        border-bottom: 1px solid $grey300;

        > p {
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
        }
      }

      > .alert {
        > p {
          // font-size: 18px;
          font-weight: bold;

          > span {
            color: $primary_color;
          }
        }
      }

      > button.skip {
        padding: 0 16px;
        height: 39px;
        border-radius: 100px;

        &::after {
          content: url('/assets/images/icon/arrow-right-white.svg');
          margin-left: 11px;
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

.pages-protected-evaluation-test-end {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
    gap: 32px;
    width: 100%;
    background: $white;
    margin-top: 32px;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      width: 100%;

      > .title {
        // font-size: 28px;
        font-weight: bold;
      }

      > .description {
        line-height: 23.2px;
        color: $grey900;
        margin-bottom: 32px;
      }

      > .go-next {
        width: 100%;
        height: 56px;
        border-radius: 32px;
      }

      > .video-box {
        #intro-video {
          display: none;
          width: 100%;
        }
      }

      > .submit {
        max-width: 460px;
        width: 100%;
        height: 56px;
        border-radius: 32px;
        margin: 0 auto;
        // font-size: 16px;
      }
    }
  }

  &.last {
    background-color: #f3f3f3;

    > .mobile-title-wrapper {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      word-break: keep-all;
      text-align: center;

      .title {
        color: $grey900;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        letter-spacing: -0.2px;
      }

      .sub-title {
        color: $grey600;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
      }
    }

    > .container {
      margin-top: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 24px;
      gap: 24px;
      width: 100%;
      background: $white;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;

      > .container-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 24px;
        width: 100%;

        > .title {
          // font-size: 28px;
          font-weight: bold;
          margin-bottom: 8px;
        }

        > .experience {
          width: 100%;
          > .experience-title {
            color: $grey900;
            margin-bottom: 14px;
          }

          > .experience-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 8px;
            margin-bottom: 16px;

            > .experience-buttons-button {
              cursor: pointer;
              width: 80px;
              height: 80px;
              display: inline-flex;
              justify-content: center;
              align-items: center;

              &::after {
                transition: opacity 0.1s;
              }

              &.point-1 {
                &::after {
                  content: url('/assets/images/icon/scores/point1.svg');
                  opacity: 0.2;
                }
              }

              &.point-2 {
                &::after {
                  content: url('/assets/images/icon/scores/point2.svg');
                  opacity: 0.2;
                }
              }

              &.point-3 {
                &::after {
                  content: url('/assets/images/icon/scores/point3.svg');
                  opacity: 0.2;
                }
              }

              &.point-4 {
                &::after {
                  content: url('/assets/images/icon/scores/point4.svg');
                  opacity: 0.2;
                }
              }

              &.point-5 {
                &::after {
                  content: url('/assets/images/icon/scores/point5.svg');
                  opacity: 0.2;
                }
              }

              &:hover {
                &::after {
                  opacity: 0.6;
                }
              }

              &.selected {
                &::after {
                  opacity: 1;
                }
              }
            }

            &.selected {
              > .experience-buttons-button {
                filter: grayscale(1);

                &.selected {
                  filter: none;
                }
              }
            }
          }

          > .experience-text-field {
            margin-bottom: 32px;
          }
        }

        > .accuracy {
          width: 100%;
          margin-bottom: 32px;

          > .accuracy-title {
            // font-size: 18px;
            font-weight: bold;
            margin-bottom: 32px;
          }

          > .accuracy-tests {
            > ul.accuracy-tests-list {
              > li.accuracy-tests-list-item {
                > .container {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: flex-start;
                  padding: 0px;
                  gap: 8px;
                  width: 100%;
                  > .skill-title {
                    width: 100%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: center;
                  }

                  > .buttons {
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    padding: 0px;

                    width: 100%;
                    height: 40px;

                    > button {
                      flex: 1;
                      padding: 0 24px;
                      height: 40px;
                      border: 1px solid $grey300;
                      background-color: transparent;
                      transition: background-color 0.1s;

                      &:hover {
                        background-color: $grey300;
                      }

                      &.selected {
                        background-color: $primary_color;
                        border: 1px solid transparent;
                        color: white;
                      }
                    }
                  }
                }

                &:not(&:last-child) {
                  margin-bottom: 32px;
                }
              }
            }
          }
        }

        > .survey {
          display: flex;
          flex-direction: column;
          gap: 24px;

          > .survey-description {
            line-height: 23.2px;
            color: $grey900;
            padding: 24px;
            background-color: $grey50;
            border-radius: 16px;
            margin-bottom: 32px;
            word-break: keep-all;
            word-wrap: break-word;
          }

          > .survey-inputs-check {
            display: flex;
            flex-direction: column;
            gap: 8px;

            > label.fs18 {
              color: $grey900;
              font-weight: 500;
              font-size: 16px !important;
              white-space: wrap;
              word-break: keep-all;
              margin-bottom: 4px;
            }

            label.MuiFormControlLabel-root {
              gap: 4px;
              margin: 0;

              .MuiSvgIcon-root {
                color: $grey900;
              }
            }

            span {
              font-size: 14px !important;
              padding: 0px !important;
            }
          }

          > .survey-inputs {
            // display: flex;
            // justify-content: space-between;
            // gap: 32px;

            > * {
              // flex: 1;
              width: 100%;
            }
            > .MuiFormControl-root:first-child {
              margin-bottom: 32px;
            }

            > .survey-inputs-right {
              display: flex;
              justify-content: space-between;
              gap: 16px;
              position: relative;

              > * {
                &:nth-child(1) {
                  flex: 1;
                }

                &:nth-child(2) {
                  flex: 2;
                }
              }

              .currency-absolute {
                position: absolute;
                z-index: 10;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
                color: $grey500;
              }
            }

            &:not(&:last-child) {
              margin-bottom: 32px;
            }
          }
        }

        > button.submit {
          width: 100%;
          height: 52px;
          border-radius: 32px;
          font-weight: 700;
          margin: 0 auto;
        }
      }
    }
  }
}

.final-alert-modal {
  position: fixed;
  bottom: 10px;
  right: -110%;
  z-index: 9999;
  width: calc(100% - 20px);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &.loading {
    right: 10px;
  }
  &.finished-loading {
    right: 10px;
  }
  &.hide {
    right: -110% !important;
  }

  > .final-alert-text-box {
    background-color: $grey900;
    color: $white;
    border-radius: 10px;
    padding: 16px 20px;
    display: flex;
    align-items: center;
    gap: 16px;

    p {
      color: $white;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%; /* 19.2px */
      letter-spacing: 0.16px;
      white-space: pre-wrap;
    }
  }
}

.pages-protected-evaluation-subjectives-video {
  &.subjective {
    > .container {
      > .spliter {
        > .spliter-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    width: 100%;
    background: $white;
    border-radius: 8px;
    margin-top: 32px;
    > .spliter {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 32px;
      width: 100%;

      > .spliter-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        > .question {
          line-height: 23.2px;
          margin-bottom: 8px;
          width: 100%;
          // background-color: $grey50;
          // padding: 10px;

          > * {
            all: revert;
            img {
              max-width: 460px;
              max-height: 240px;
              height: auto;
              margin: 0 auto;
              display: block;
              width: 100%;
              margin-top: 16px;
            }
          }
          > pre {
            white-space: pre-wrap;
            word-break: keep-all;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 8px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .timer {
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 3px;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            display: block;
            width: 100%;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }
      }

      > .spliter-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 32px;
        width: 100%;

        > .video {
          width: 100%;
          height: 100%;
          // height: 345px;
          background-color: $black;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;

          > video {
            display: block;
            // max-width: 100%;
            height: 100%;
            transform: rotateY(180deg);
          }

          video::-webkit-media-controls {
            transform: rotateY(180deg) !important;
          }

          &.start {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: hidden;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-timeline {
                visibility: hidden;
              }
            }
          }

          &.stop {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: visible;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: visible;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: visible;
              }

              &::-webkit-media-controls-timeline {
                visibility: visible;
              }
            }
          }
        }

        > .buttons {
          display: flex;
          gap: 24px;
          width: 100%;

          > button {
            flex: 1;
            height: 56px;
            border-radius: 32px;
          }
        }

        > .timer {
          font-weight: 700;
          color: $blue500;
          &.stop {
            display: none;
          }

          &::before {
            content: url('/assets/images/icon/timer.svg');
            margin-right: 7px;
            position: relative;
            top: 3px;
          }
        }

        > .record-cnt {
          &.start {
            display: none;
          }

          i {
            color: $blue500;
          }
          font-weight: bold;
        }

        > .alert {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 16px;
          gap: 8px;
          width: 100%;
          background: $grey50;
          border-radius: 16px;

          > .alert-title {
            // font-size: 18px;
            font-weight: bold;
          }

          > ul.alert-list {
            > li.alert-list-item {
              position: relative;
              padding-left: 10px;
              line-height: 23.2px;
              word-break: keep-all;
              word-wrap: break-word;

              &::before {
                content: '';
                position: absolute;
                top: 9px;
                left: 0px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: $grey900;
              }
            }
          }
        }
      }
    }

    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-subjectives-essay {
  &.subjective {
    > .container {
      > .spliter {
        > .spliter-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    width: 100%;

    > .spliter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 32px;
      width: 100%;
      background: $white;
      border-radius: 8px;

      > .spliter-left {
        flex: 1;
        width: 100%;
        > .question {
          // font-weight: bold;
          color: $grey900;
          line-height: 23.2px;
          margin-bottom: 8px;
          word-break: keep-all;
          word-wrap: break-word;
          // background-color: $grey50;
          // padding: 10px;

          > * {
            all: revert;
            img {
              max-width: 460px;
              max-height: 240px;
              height: auto;
              margin: 0 auto;
              display: block;
              width: 100%;
              margin-top: 16px;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 8px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .timer {
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: 100%;

        #mceu_15 {
          min-height: 160px;
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-subjectives-code {
  > .container {
    width: 100%;

    > .spliter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 32px;
      width: 100%;
      background: $white;
      border-radius: 8px;

      > .spliter-left {
        flex: 1;
        width: 100%;
        > .question {
          // font-weight: bold;
          color: $grey900;
          line-height: 23.2px;
          margin-bottom: 8px;
          word-break: keep-all;
          word-wrap: break-word;
          // background-color: $grey50;
          // padding: 10px;

          > * {
            all: revert;
            img {
              max-width: 100%;
              height: auto;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 8px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .timer {
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: 100%;

        .editor-select {
          // height: 48px;
          padding: 8px 16px;
          background-color: $grey800;
          position: relative;

          > span {
            display: block;
            background-color: $grey700;
            color: $white;
            padding: 6px 8px;
            border-radius: 4px;
          }
        }

        .editor-input {
          position: relative;
          height: 402px;
        }

        .editor-result-button-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;
          background-color: $grey800;

          > span {
            display: inline-block;
            font-weight: bold;
            color: $white;
          }
          > button {
            padding: 10.5px 12px;
            border-radius: 4px;
            display: flex;
            align-items: center;

            > svg {
              margin-left: 10px;
            }
          }
        }

        .editor-output {
          position: relative;
          height: 210px;
        }

        .output-result-box {
          background-color: $grey900;
          height: 100%;
          padding: 8px 16px;
          overflow-y: auto;

          > p,
          pre {
            color: $white;
          }
        }

        #mceu_15 {
          min-height: 160px;
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-objectives-common {
  > .container {
    display: flex;
    padding: 24px;
    width: 100%;
    background: $white;
    border-radius: 8px;
    margin-top: 32px;

    > .separater {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 32px;
      width: 100%;

      > .separater-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;

        > .question {
          // font-weight: bold;
          line-height: 23.2px;
          color: $grey900;
          word-break: keep-all;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          width: 100%;
          // background-color: $grey50;
          // padding: 10px;

          > * {
            all: revert;
            img {
              max-width: 460px;
              max-height: 240px;
              height: auto;
              margin: 0 auto;
              display: block;
              width: 100%;
              margin-top: 16px;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 8px;

            * {
              all: revert;
              margin: 0 !important;
            }

            .rect-border {
              border: 1px solid $grey300;
              padding: 8px 10px;

              .center {
                padding: 20px 0;
              }
            }

            .center {
              text-align: center;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }

          > .timer {
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }
        }

        > .label {
          color: $grey500;
          margin-bottom: 8px;
        }
      }

      > .separater-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 100%;

        > .label {
          color: $grey500;
          width: 100%;
        }

        > .answer {
          width: 100%;
          > ul.answer-list {
            width: 100%;
            > li.answer-list-item {
              width: 100%;
              border: 1px solid $grey100;
              border-radius: 4px;
              cursor: pointer;

              &:hover {
                background: $grey50;
              }

              &:not(&:last-child) {
                margin-bottom: 16px;
              }

              > .container {
                display: flex;
                align-items: center;
                padding: 4px;
                border-radius: 4px;
                > p {
                  // word-break: break-all;
                  word-break: keep-all;
                  word-wrap: break-word;
                }
                .MuiMenuItem-root {
                  padding: 0 !important;
                }
                > .hasImage {
                  width: 100%;
                  text-align: center;
                  > img {
                    max-width: 100%;
                    max-height: 160px;
                  }
                }
              }

              &.selected {
                > .container {
                  background-color: $blue500;

                  > .radio-button {
                    svg {
                      color: white;
                      > path {
                      }
                    }
                  }

                  > p {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-subjectives-multiple-choice {
  &.subjective {
    > .container {
      > .separater {
        > .separater-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    display: flex;
    padding: 24px;
    width: 100%;
    background: $white;
    border-radius: 8px;
    margin-top: 32px;

    > .separater {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 32px;
      width: 100%;

      > .separater-left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;

        > .question {
          // font-weight: bold;
          line-height: 23.2px;
          color: $grey900;
          word-break: keep-all;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          width: 100%;
          // background-color: $grey50;
          // padding: 10px;

          > * {
            all: revert;
            img {
              max-width: 460px;
              max-height: 240px;
              height: auto;
              margin: 0 auto;
              display: block;
              width: 100%;
              margin-top: 16px;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 8px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }

          > .timer {
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }
        }

        > .label {
          color: $grey500;
          margin-bottom: 8px;
        }
      }

      > .separater-right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 16px;
        width: 100%;

        > .label {
          color: $grey500;
          width: 100%;
        }

        > .answer {
          width: 100%;
          > ul.answer-list {
            width: 100%;
            > li.answer-list-item {
              width: 100%;
              border: 1px solid $grey100;
              border-radius: 4px;
              cursor: pointer;

              &:hover {
                background: $grey50;
              }

              &:not(&:last-child) {
                margin-bottom: 16px;
              }

              > .container {
                display: flex;
                align-items: center;
                padding: 4px;
                border-radius: 4px;
                > p {
                  // word-break: break-all;
                  word-break: keep-all;
                  word-wrap: break-word;
                }
                .MuiMenuItem-root {
                  padding: 0 !important;
                }
                > .hasImage {
                  width: 100%;
                  text-align: center;
                  > img {
                    max-width: 100%;
                    max-height: 160px;
                  }
                }
              }

              &.selected {
                > .container {
                  background-color: $blue500;

                  > .radio-button {
                    svg {
                      color: white;
                      > path {
                      }
                    }
                  }

                  > p {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.pages-protected-evaluation-obpitest {
  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 24px;
    width: 100%;
    background: $white;
    margin-top: 32px;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 64px;
      width: 100%;
      > .discription {
        width: 100%;
        height: 23px;
        font-weight: 500;
        display: flex;
        align-items: center;
        color: $grey700;
        word-break: keep-all;
        word-wrap: break-word;

        &.khai {
          height: auto;
          align-items: flex-start;
          flex-direction: column;
          gap: 16px;
        }
      }
      > .testlist {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        gap: 8px;
        width: 100%;
        > .question {
          width: 100%;
          font-style: normal;
          font-weight: 700;
          align-items: center;
          text-align: center;
          color: $grey900;
          word-break: keep-all;
          word-wrap: break-word;
          font-size: 18px;
          // background-color: $grey50;
          // padding: 10px;

          > * {
            all: revert;
            img {
              max-width: 460px;
              max-height: 240px;
              height: auto;
              margin: 0 auto;
              display: block;
              width: 100%;
              margin-top: 16px;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 8px;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }
        > .answer {
          width: 100%;
          > .answer-list {
            display: flex;
            width: 100%;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid $grey100;

            .answer-list-item {
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 4px;
              gap: 4px;
              flex: 1;
              background: $white;
              // border: 1px solid $grey100;
              text-align: center;
              cursor: pointer;
              vertical-align: middle;
              font-size: 0;
              width: 100%;
              transition: all 0.2 ease-in-out;

              > span {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                align-items: center;
                color: $grey900;
                word-break: keep-all;
                word-wrap: break-word;
              }

              &:not(:last-child) {
                border-right: 1px solid $grey100;
              }

              &:hover {
                background: $grey100;
              }
              &.selected {
                background: $blue500;
                border-color: $blue500;
                > span {
                  color: $white;
                }
              }
              &.disabled {
                border: 1px solid $grey100;
                color: $grey300;
                cursor: default;
              }
            }
            .answer-khai-list-item {
              box-sizing: border-box;
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 10px 4px;
              gap: 16px;
              flex: 1;
              background: $white;
              cursor: pointer;
              width: 100%;
              transition: all 0.2 ease-in-out;

              > span {
                width: 100%;
                font-style: normal;
                font-weight: 500;
                align-items: center;
                text-align: center;
                color: $grey900;
              }

              &:not(:last-child) {
                border-right: 1px solid $grey100;
              }

              &:hover {
                background: $grey100;
              }
              &.selected {
                background: $grey900;
                border-color: $grey900;
                > span {
                  color: $white;
                }
              }
              &.disabled {
                border: 1px solid $grey100;
                color: $grey300;
                cursor: default;
              }
            }
          }
        }
      }
      > .button-next {
        width: 100%;
        > button {
          display: flex;
          height: 56px;
          justify-content: center;
          padding: 8px 24px;
          align-items: center;
          gap: 8px;
          border-radius: 4px;
          margin: 0 auto;

          &.inactive {
            color: $white;
            background-color: $blue200;
          }
        }
      }
    }
  }
}

.pages-protected-evaluation-subjectives-file-upload {
  &.subjective {
    > .container {
      > .container-inner {
        > .separater {
          > .separater-left {
            > .question {
              background-color: $white !important;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    margin-top: 32px;
    width: 100%;
    background: $white;
    border-radius: 8px;
    > .container-inner {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      > .separater {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 32px;
        width: 100%;

        > .separater-left {
          width: 100%;
          padding-right: 20px;

          > .question {
            // font-size: 18px;
            // font-weight: bold;
            // line-height: 23.2px;
            margin-bottom: 8px;
            // background-color: $grey50;
            // padding: 10px;

            > * {
              all: revert;
              img {
                max-width: 460px;
                max-height: 240px;
                height: auto;
                margin: 0 auto;
                display: block;
                width: 100%;
                margin-top: 16px;
              }
            }
            > pre {
              white-space: pre-wrap;
              margin: 0;
              margin-bottom: 8px;
              line-height: 1.8 !important;
              // text-indent: 8px;

              * {
                all: revert;
                margin: 0 !important;
              }
            }

            > .timer {
              // font-size: 18px;

              &::before {
                content: url('/assets/images/icon/timer.svg');
                margin-right: 7px;
                position: relative;
                top: 4px;
              }
            }

            .video-box {
              margin-bottom: 32px;
              .question-video {
                display: block;
                width: 100%;
              }
            }
          }
        }

        > .separater-right {
          width: 100%;
          // height: calc(100vh - 120px - 99px - 80px); /*전체 박스에서 상하단 padding제외*/
          overflow-y: auto;
          padding-right: 20px;

          > .answer {
            width: 100%;
            height: 200px;
            border-radius: 16px;
            background-color: $grey100;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              background-color: rgba($grey100, 0.6);
            }

            &:active {
              background-color: rgba($grey100, 0.8);
            }

            > .file-upload-icon {
              margin-bottom: 16px;
            }

            > .file-upload-name {
              margin-top: 8px;
            }
          }
        }
      }

      > button.submit {
        width: 460px;
        height: 56px;
        border-radius: 32px;
      }
    }
  }
}

.pages-protected-evaluation-subjectives-spreadsheets {
  > .container {
    display: flex;
    width: 100%;
    background: $white;
    border-radius: 8px;
    margin-top: 32px;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 24px;
      width: 100%;

      > .header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        > .header-question {
          // font-size: 18px;
          font-weight: bold;
        }

        > .header-submit {
          height: 56px;
          border-radius: 32px;
          padding: 0 40px;
        }
      }

      > .spreadsheets {
        // flex: 1;

        > iframe {
          width: 100%;
          // height: calc(100vh - 340px);
        }
      }
    }
  }
}

.pages-protected-evaluation-objectives-culture-fit {
  > .container {
    width: 100%;
    padding: 40px;
    background-color: white;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding-right: 20px;

    > .title {
      // font-size: 20px;
      font-weight: bold;
      margin-bottom: 32px;
    }

    > .attributes {
      margin-bottom: 32px;

      > ul.attributes-list {
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        // gap: 16px;
        display: grid;
        grid-template-columns: repeat(auto-fill, 160px);
        grid-gap: 16px;
        justify-content: space-between;

        > li.attributes-list-item {
          > .container {
            cursor: pointer;
            width: 179px;
            padding: 16px;
            display: inline-flex;
            flex-direction: column;
            border: 1px solid $grey100;
            transition: border 0.1s, background-color 0.1s;
            border-radius: 4px;

            > span {
              transition: color 0.1s;

              &:nth-child(1) {
                color: $grey900;
                margin-bottom: 4px;
              }

              &:nth-child(2) {
                font-size: 12px;
                color: $grey500;
              }
            }

            &:hover {
              border: 1px solid $primary_color;
            }
          }

          &.selected {
            > .container {
              background-color: $primary_color;

              &:hover {
                background-color: rgba($primary_color, 0.6);
                border: 1px solid transparent;
              }

              > span {
                color: white;
              }
            }
          }

          &.disabled {
            > .container {
              cursor: default;

              &:hover {
                border: 1px solid $grey100;
              }

              > span {
                color: $grey300;
              }
            }

            &.selected {
              > .container {
                cursor: pointer;

                &:hover {
                  background-color: rgba($primary_color, 0.6);
                }

                > span {
                  color: white;
                }
              }
            }
          }
        }
      }
    }

    > button.submit,
    > button.next {
      width: 460px;
      height: 56px;
      border-radius: 32px;
      margin: 0 auto;
    }
  }
}

.pages-protected-evaluation-subjectives-styling {
  > .container {
    width: 100%;

    > .spliter {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 24px;
      gap: 32px;
      width: 100%;
      background: $white;
      border-radius: 8px;

      > .spliter-left {
        flex: 1;
        width: 100%;
        > .question {
          // font-weight: bold;
          color: $grey900;
          line-height: 23.2px;
          margin-bottom: 8px;
          word-break: keep-all;
          word-wrap: break-word;

          > * {
            all: revert;
            img {
              max-width: 460px;
              max-height: 240px;
              height: auto;
              margin: 0 auto;
              display: block;
              width: 100%;
              margin-top: 16px;
            }
          }
          > pre {
            white-space: pre-wrap;
            margin: 0;
            margin-bottom: 8px;
            line-height: 1.8 !important;
            // text-indent: 8px;

            * {
              all: revert;
              margin: 0 !important;
              line-height: 1 !important;
            }
          }

          > .timer {
            &::before {
              content: url('/assets/images/icon/timer.svg');
              margin-right: 7px;
              position: relative;
              top: 4px;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: 100%;

        #mceu_15 {
          min-height: 160px;
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

.single-chart {
  width: 100%;
  justify-content: space-around;
}

.circular-chart {
  display: block;
  max-width: 100%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: $grey100;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 3.8;
  stroke-linecap: butt;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.blue .circle {
  stroke: $blue500;
}
.circular-chart.red .circle {
  stroke: $red500;
}
.circular-chart.orange .circle {
  stroke: $orange500;
}

.pages-protected-evaluation-done {
  .header {
    width: 100%;
  }

  .header-img {
    width: 240px;
    height: 240px;
    background-image: url('/assets/images/icon/testtaker/img-final-evaluation.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin: 0 auto;
  }
  .header-description {
    color: $grey900;
    line-height: 23.2px;
    text-align: center;
    > .center {
      display: inline-block;
      margin: 16px auto 0;
    }
  }

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 24px;
    gap: 32px;
    width: 100%;
    background: $white;
    margin-top: 32px;
    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;

      > .header-title {
        // width: 960px;
        font-weight: 700;
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        color: $grey900;
      }
      > .header-description {
        margin: 8px auto 0;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        color: $grey900;
      }
      > .objective-result {
        width: 100%;
        align-items: center;
        margin-top: 32px;
        > .average-score {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 8px;
          width: 100%;
          height: 186px;
          margin: 0 auto;
          background: $white;
          border: 1px solid $grey100;
          border-radius: 4px;
          > .average-score-title {
            height: 26px;
            font-weight: 700;
            display: flex;
            align-items: center;
            text-align: center;
            color: $grey900;
          }
          > .average-score-graph {
            margin-top: 8px;
            width: 120px;
            height: 120px;
            text-align: center;
            > .percentage {
              position: relative;
              bottom: 71%;
              > .number {
                font-weight: 700;
              }
              > .unit {
                font-weight: 700;
              }
            }
          }
        }
        > .result-objective {
          width: 100%;
          margin-top: 32px;
          > .result-objective-title {
            // font-size: 20px;
            font-weight: bold;
            color: $grey900;
            margin-bottom: 16px;
          }

          > .result-objective-table {
            thead {
              border: 1px solid $grey300;
            }
            tr {
              border-left: 1px solid $grey300;
              border-right: 1px solid $grey300;
              border-bottom: 1px solid $grey300;
            }
            .thead {
              // font-size:16px;
              font-weight: 700;
              // letter-spacing: -0.01em;
              &.title {
              }

              &.score {
              }

              &.survey {
              }
            }

            .tbody {
              font-weight: 500;
              &.title {
              }

              &.category {
                padding-left: 32px;
              }

              &.score {
                > .container {
                  > span {
                  }

                  > .progress-bar {
                    display: none;
                    position: relative;
                    width: 100%;
                    height: 4px;
                    background-color: $grey100;
                    border-radius: 4px;

                    > .fill {
                      height: 4px;
                      position: absolute;
                      top: 0;
                      left: 0;
                      border-radius: 4px;
                      &.blue {
                        background-color: $blue500;
                      }
                      &.red {
                        background-color: $red500;
                      }
                      &.orange {
                        background-color: $orange500;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      > .obpi-result {
        margin-top: 32px;
        width: 100%;
        > .obpi-result-title {
          width: 100%;
          height: 26px;
          font-style: normal;
          font-weight: 700;
          display: flex;
          align-items: center;
          color: $grey900;
        }
        > .obpi-detail-result {
          margin-top: 8px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 24px;
          gap: 32px;
          width: 100%;
          border: 1px solid $grey100;
          border-radius: 8px;
          > .obpi-detail-result-title {
            width: 100%;
            font-style: normal;
            font-weight: 700;
            display: flex;
            align-items: center;
            color: $grey900;
          }
          > .obpi-detail-result-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 4px;
            width: 100%;
            > .obpi-detail-result-title {
              width: 100%;
              font-style: normal;
              font-weight: 700;
              align-items: center;
              text-align: center;
              color: $grey900;
            }
            > .obpi-detail-results {
              width: 100%;
              > .obpi-detail-result-answer {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: flex-end;
                padding: 0px;
                gap: 4px;
                width: 100%;
                height: 16px;
                > .obpi-detail-result-answer-item {
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;
                  gap: 10px;
                  width: 11%;
                  height: 16px;
                  background: $grey100;
                  border-radius: 4px;
                  &.selected {
                    background: $blue500;
                    &::after {
                      width: 12px;
                      height: 12px;
                      display: inline-block;
                      content: '';
                      -webkit-mask: url('/assets/images/icon/Star.svg')
                        no-repeat 50% 50%;
                      mask: url('/assets/images/icon/Star.svg') no-repeat 50%
                        50%;
                      -webkit-mask-size: cover;
                      mask-size: cover;
                      background-color: $white;
                    }
                  }
                }
              }
            }
            > .opbi-detail-result-discription-box {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
              padding: 0px;
              gap: 16px;
              width: 100%;
              height: 16px;
              > .discription {
                width: 50%;
                height: 16px;
                font-style: normal;
                font-weight: 700;
                align-items: center;
                color: $grey700;
                &.left {
                  text-align: left;
                }
                &.right {
                  text-align: right;
                }
              }
            }
          }
          > .obpi-detail-result-error {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0px;
            gap: 8px;
            width: 100%;
            > .title {
              font-style: normal;
              font-weight: 700;
              display: flex;
              align-items: center;
              color: $grey900;
            }
            > .desc {
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 145%;
              display: flex;
              align-items: center;
              color: $grey900;
              > a {
                color: $blue500;
                margin-left: 5px;
                text-decoration: none;
              }
            }
          }
        }
      }

      > .footer {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 8px;
        width: 100%;
        background: $grey50;
        border-radius: 4px;
      }
    }
  }
}

//Demo test taker css
.pages-demo-voice-test {
  height: calc(100vh - 56px - 132px);
  background-color: $grey100;
  padding: 0 24px !important;

  .container {
    max-width: 400px;

    .setting-title {
      color: $grey900;
      padding: 16px 0;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.24px;
    }
    .container-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 16px;

      .container-right {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 16px;

        .header {
          color: $grey800;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.14px;
          text-align: center;
        }

        .read-sentence {
          .read-sentence-wrapper {
            border: 1px solid $grey300;
            padding: 12px 20px;
            width: 100%;
            background: $grey200;
            text-align: center;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.2px;

            p {
              font-weight: 700;
              font-size: 20px !important;
              background: var(
                --black-gradient,
                linear-gradient(90deg, #000 5.59%, rgba(0, 0, 0, 0) 108.38%)
              );
              -webkit-background-clip: text;
              background-clip: text;
              -webkit-text-fill-color: transparent;
              background-size: 300% auto;
              animation: text-gradient 4s ease-in-out infinite;
              -webkit-animation: text-gradient 4s ease-in-out infinite alternate;
            }

            @keyframes text-gradient {
              0% {
                background-position: 0% 50%;
              }
              100% {
                background-position: 100% 50%;
              }
            }
          }
        }

        .button-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 8px;

          .submit {
            width: 100%;
            max-width: 600px;
            height: 52px;
            border-radius: 50px;
            text-align: center;
            color: white;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 75%;
            letter-spacing: -0.32px;
          }

          .loading {
            text-align: center;
            background-color: $grey300;
            color: $grey900;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 24px;
          }

          .helper {
            p {
              text-align: center;
              word-break: keep-all;
              color: $grey800;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }

      .container-left {
        .video-stream-contents {
          .video-container {
            display: flex;
            flex-direction: column;
            position: relative;

            .video-box {
              text-align: center;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 0;
              position: relative;

              .video {
                width: 100%;
                max-height: 234px;
              }

              .face-canvas {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 50%;
                transform: rotateY(180deg) translateX(50%);
              }

              .record-time {
                background-color: rgba(0, 0, 0, 0.5);
                position: absolute;
                width: 100%;
                bottom: 0;
                left: 0;
                display: flex;
                gap: 8px;
                align-items: center;
                justify-content: center;
                z-index: 1;
                padding: 4px 0;

                * {
                  color: $white;
                  fill: $white;
                  font-weight: 700;
                }
              }

              .video-bg {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 2;
                overflow: hidden;

                .video-bg-rect {
                  top: 20px;
                  left: 50%;
                  transform: translateX(-50%);
                  max-width: 90%;
                  width: 100%;
                  height: calc(100% - 94px);
                  position: absolute;
                  border: 1px solid $white;
                  border-radius: 8px;

                  &::after {
                    overflow: hidden;
                    top: -2px;
                    left: -2px;
                    content: '';
                    position: absolute;
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                    border-radius: 8px;
                    box-shadow: 0 0 0 999px rgba($black, 0.2);
                  }
                }
              }
            }
            .media-setting-alert {
              display: inline-block;
              background: $grey900;
              border-radius: 32px;
              padding: 8px 16px;
              position: absolute;
              bottom: 20px;
              right: 10px;
              z-index: 20;

              .media-setting-icon {
                display: inline-block;

                svg {
                  path {
                    fill: $white;
                  }
                }
              }

              .media-setting-text {
                display: none;
              }

              &:hover {
                background-color: $grey100;
              }
            }
          }
        }
      }
    }
  }
}

// survey 페이지
.demo-pages-protected-evaluation-test-end {
  padding: 0 !important;
  background: $grey150;
  position: relative;
  padding: 0 24px !important;
  width: 100%;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
    max-width: 400px;

    > .container-inner {
      display: flex;
      flex-direction: column;
      gap: 48px;
      padding: 0px;
      width: 100%;
      word-break: keep-all;

      > .title {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 59.2px */
        letter-spacing: -0.28px;
      }

      > .description {
        color: $grey800;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        letter-spacing: -0.18px;
      }

      > .go-next {
        width: 100%;
        height: 52px;
        font-size: 16px;
        border-radius: 50px;
        margin: 0 auto;
        font-weight: 700;
      }

      > .video-box {
        margin-bottom: 32px;

        #intro-video {
          // display: none;
          width: 100%;
        }
      }

      > .submit {
        max-width: 460px;
        width: 100%;
        height: 56px;
        border-radius: 32px;
        margin: 0 auto;
      }
    }
  }
  &.last {
    > .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      width: 100%;
      padding: 40px 0;

      > .container-inner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0px;
        width: 100%;
        gap: 16px;

        > .title-box {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
          align-items: center;
          margin-bottom: 8px;

          > .title {
            font-weight: 500;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%; /* 37px */
            letter-spacing: -0.24px;
            color: $grey900;
          }

          > .desc {
            font-size: 12px !important;
            font-style: normal;
            font-weight: 400;
            line-height: 16px; /* 183.333% */
            color: $grey600;
          }
        }

        > .experience {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 16px;

          > .experience-title {
            text-align: center;
            color: $grey900;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 145%;
            letter-spacing: -0.16px;
          }

          > .experience-buttons {
            display: flex;
            justify-content: center;
            align-items: center;

            > .experience-buttons-button {
              flex: 1;
              cursor: pointer;
              display: inline-flex;
              justify-content: center;
              align-items: center;
              padding: 0 6px;

              &.selected {
                > .button-content-box {
                  .button-icon-box {
                    background-color: $grey900;

                    svg {
                      path {
                        fill: $white;
                      }
                    }
                  }
                }
              }

              > .button-content-box {
                display: flex;
                flex-direction: column;
                align-items: center;

                .button-icon-box {
                  padding: 4px 9px;
                  background-color: $white;
                  border-radius: 10px;

                  > svg {
                    width: 32px;
                    height: 32px;
                  }
                }

                .button-title-box {
                  display: none;
                  color: $grey800;
                  font-size: 13px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }
              }
            }
          }

          > .experience-text-field {
            margin-bottom: 8px;
          }
        }

        > .survey {
          margin-bottom: 8px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;

          > .survey-description {
            line-height: 23.2px;
            color: $grey900;
            padding: 24px;
            background-color: $grey50;
            border-radius: 16px;
            margin-bottom: 32px;
            word-break: keep-all;
            word-wrap: break-word;
          }

          > .survey-inputs-check {
            margin-bottom: 32px;

            > label.fs18 {
              color: $grey900;
              font-weight: 500;
            }
          }

          > .survey-inputs {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 24px;

            > * {
              width: 100%;
            }

            > .survey-inputs-right {
              display: flex;
              justify-content: space-between;
              gap: 10px;
              position: relative;

              > * {
                &:nth-child(1) {
                  flex: 1;
                }

                &:nth-child(2) {
                  flex: 2;
                }
              }

              .currency-absolute {
                position: absolute;
                z-index: 10;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);
                color: $grey500;
              }
            }
          }
        }

        > button.submit {
          width: 100%;
          height: 52px;
          font-size: 16px;
          border-radius: 50px;
          font-weight: 700;
        }
      }
    }
  }
}

// 테스트 시작 페이지
.demo-pages-protected-evaluation-test-start {
  padding: 0 24px !important;
  max-width: 400px;
  margin: 0 auto;

  > .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    > .container-inner {
      display: flex;
      flex-direction: column;
      padding: 0px;
      width: 100%;

      > .title {
        display: flex;
        margin-bottom: 12px;

        > .title-main {
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: -0.28px;
        }
      }

      > .desc {
        margin-bottom: 48px;

        > p {
          color: $grey900;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: -0.14px;
          word-break: keep-all;
          word-wrap: break-word;
        }
      }

      > .alert {
        margin-bottom: 48px;

        > p {
          color: $grey900;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          letter-spacing: -0.18px;

          > span {
            color: $grey800;
            text-decoration-line: underline;
          }
        }
      }

      > button.skip {
        cursor: pointer;
        width: 100%;
        height: 52px;
        font-size: 16px;
        border-radius: 50px;
        margin: 0 auto;
        font-weight: 700;
      }
    }
  }
}

// 비디오
.demo-pages-protected-evaluation-subjectives-video {
  padding: 0px !important;
  &.subjective {
    > .container {
      position: relative;
      > .spliter {
        > .spliter-left {
          > .question {
            background-color: $white !important;
            padding: 0 !important;
          }
        }
      }
    }
  }

  > .container {
    padding: 16px 24px;
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    &.role-playing {
      align-items: flex-start;
    }

    > .role-title {
      color: $grey900;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.24px;
      margin-bottom: 16px;
      text-align: left;
      width: 100%;
    }

    > .question-modal {
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(8px);
      width: 100%;
      height: 100%;
      z-index: 0;
      transition: all 0.3s ease-in-out;
      border-radius: 8px;
      padding: 40px;
      text-align: center;

      &.show {
        transform: scale(1);
        z-index: 30;
      }

      &.hide {
        transform: scale(0);
      }
    }

    > .spliter {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;

      > .spliter-left {
        flex: 1;
        width: 100%;

        &.role-playing {
          background: $grey200;

          > .question {
            padding: 20px;
            height: 100%;

            hr {
              border: 0;
              height: 1px;
              background: $grey500;
              margin: 16px 0;
            }
            pre {
              color: $grey800;
              font-size: 21px;
              font-style: normal;
              font-weight: 400;
              line-height: 32px;
            }
          }
        }

        > .question {
          line-height: 23.2px;
          overflow-y: auto;
          word-break: keep-all;
          word-wrap: break-word;
          font-size: 24px;

          > .role-title {
            color: $grey900;
            font-size: 24px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: -0.24px;
            margin-bottom: 16px;
          }

          > * {
            all: revert;
            img {
              margin-top: 16px;
              max-width: 100%;
              height: auto;
              max-height: 500px;
              margin-top: 16px;
              width: 100%;
            }
          }

          > a {
            display: block;
            width: 100%;
            text-align: center;

            img {
              margin-top: 0;
              height: auto;
              width: 100%;
              margin-left: auto;
              margin-right: auto;
            }
          }

          > pre {
            font-size: 14px !important;
            white-space: pre-wrap;
            margin: 0;
            color: $grey800;
            font-weight: 400 !important;
            line-height: 24px !important;

            * {
              all: revert;
              margin: 0 !important;
            }
          }

          > .video-box {
            margin-bottom: 32px;
            .question-video {
              display: block;
              width: 100%;
            }
          }
        }

        > .demo-timer {
          display: flex;
          justify-content: center;
          background-color: $grey200;
          border-radius: 8px;
          padding: 32px 0px 50px 0px;
          display: none;

          .timer-wrapper {
            display: flex;
            flex-direction: column;
            .timer-title {
              color: $grey900;
              font-size: 20px;
              font-style: normal;
              font-weight: 700;
              line-height: 120%;
              letter-spacing: -0.2px;
              margin-bottom: 12px;
            }

            .time-box {
              display: flex;
              color: white;
              text-align: center;
              font-size: 40px;
              font-style: normal;
              font-weight: 500;
              line-height: 75%;
              letter-spacing: -0.4px;
              margin-bottom: 15px;
              gap: 8px;
              .timer-container {
                display: flex;
                flex-direction: column;
                .time-title {
                  display: flex;
                  color: $grey700;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  letter-spacing: -0.16px;
                  margin-bottom: 15px;
                }
                > .timer-box {
                  border-radius: 4px;
                  background: black;
                  height: 90px;
                  padding: 20px 30px;
                  align-items: center;
                  gap: 24px;
                  flex: 1;
                  display: flex;

                  > div {
                    width: 50px;
                  }

                  > .timer-line {
                    width: 1px;
                    background: #454d54;
                    height: 60px;
                  }
                }
              }
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: 100%;

        &.role-playing {
          > .video {
            width: 100%;
            max-height: 234px;
            display: block;
          }

          > .record-cnt {
            &.start {
              display: block !important;
            }
          }
        }

        > .demo-timer {
          margin-bottom: 20px;

          .timer-wrapper {
            display: flex;
            flex-direction: column;

            .time-box {
              display: flex;
              color: $black;
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 145%;
              letter-spacing: -0.18px;
              gap: 16px;

              .timer-container {
                display: flex;
                flex-direction: column;
                min-width: 77px;

                .time-title {
                  display: flex;
                  color: $grey700;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 120%;
                  letter-spacing: -0.1px;
                  margin-bottom: 4px;
                }
                > .timer-box {
                  flex: 1;
                  display: flex;

                  .timer-icon-box {
                    margin-right: 4px;
                  }
                }
              }
            }
          }
        }

        > .video {
          width: 100%;
          max-height: 234px;
          background-color: $black;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          position: relative;
          border-radius: 8px;
          margin-bottom: 20px;

          > video {
            width: 100%;
            max-width: 100%;
            max-height: 100%;
          }

          &.start {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: hidden;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: hidden;
              }

              &::-webkit-media-controls-timeline {
                visibility: hidden;
              }
            }
          }

          &.stop {
            > video {
              &::-webkit-media-controls-play-button {
                visibility: visible;
              }

              &::-webkit-media-controls-current-time-display {
                visibility: visible;
              }

              &::-webkit-media-controls-time-remaining-display {
                visibility: visible;
              }

              &::-webkit-media-controls-timeline {
                visibility: visible;
              }
            }
          }
        }

        > .alert-demo {
          padding: 16px;
          background-color: $grey50;
          border-radius: 8px;
          margin-bottom: 32px;

          &.stop {
            display: none;
          }
        }

        > .buttons {
          display: flex;
          gap: 24px;

          > button {
            flex: 1;
            height: 52px;
            border-radius: 32px;
            font-weight: 700;

            &:disabled {
              cursor: default;
              background-color: $grey300;
            }
          }
        }

        > .record-cnt {
          color: $grey800;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0.12px;
        }

        > .record-helper {
          margin-bottom: 20px;

          p {
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 145%; /* 23.2px */
            letter-spacing: -0.16px;
            color: $grey800;

            i {
              color: $red500;
            }
          }
        }

        > .record-grey-box {
          margin-bottom: 40px;

          p {
            text-align: center;
            padding: 12px 20px;
            background-color: $grey200;
            border: 1px solid $grey300;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%; /* 20px */
            letter-spacing: -0.2px;
          }
        }

        > .alert {
          padding: 16px;
          border-radius: 8px;
          display: flex;
          gap: 16px;
          align-items: center;
          justify-content: space-between;

          &.demo {
            gap: 8px;
            padding: 0;
            justify-content: left;
            margin-bottom: 24px;
            font-size: 14px;
          }

          &.start {
            display: none;
          }

          > .alert-title {
            font-weight: 500;
          }
        }
      }
    }

    > .submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// 스타일링 주관식
.demo-styling-pages-protected-evaluation-subjectives-essay {
  padding: 0 !important;
  width: 100%;
  padding: 32px 0 !important;
  background-color: #f3f3f3;

  > .container {
    max-width: 400px;
    width: 100%;
    padding: 24px;
    background-color: $white;
    border-radius: 8px;

    > .spliter {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;

      > .spliter-left {
        flex: 1;
        width: 100%;
        overflow-y: auto;

        > .question {
          color: $grey900;
          line-height: 23.2px;
          word-break: keep-all;
          word-wrap: break-word;
          display: flex;
          flex-direction: column;
          gap: 20px;

          p,
          pre {
            color: #1a1a1a;
            font-size: 21px;
            font-style: normal;
            font-weight: 400;
            line-height: 32px; /* 152.381% */
            letter-spacing: -0.5px;
            word-break: keep-all;
            word-wrap: break-word;
            overflow-y: auto;
            font-weight: 700 !important;
          }

          > .styling-title-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
              font-size: 28px;
              font-style: normal;
              font-weight: 500;
              line-height: 100%; /* 20.72px */
              letter-spacing: -1px;
            }
          }

          > .style-image-box {
            height: auto;
            border: 2px solid #eeeeee;
            border-radius: 10px;
            overflow: hidden;

            > .style-image {
              display: block;
              width: 100%;
            }
          }
        }
      }

      > .spliter-right {
        flex: 1;
        width: 100%;

        // .tox-tinymce {
        //   height: 100% !important;
        // }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// 객관식 테스트
.demo-pages-protected-evaluation-objectives-common {
  background-color: $grey150;
  padding: 16px 0 32px !important;

  > .objectives-title-box {
    width: 100%;
    padding: 0 24px;

    h3 {
      font-size: 28px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 37px */
      letter-spacing: -0.24px;
      padding-bottom: 16px;
    }
  }

  > .container {
    max-width: 400px;
    width: 100%;
    padding: 24px;
    gap: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
    background-color: $white;

    > .objectives-title-box {
      h3 {
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 37px */
        letter-spacing: -0.24px;
        display: none;
      }
    }

    > .separater {
      width: 100%;
      display: flex !important;
      justify-content: space-between;
      flex-direction: column;
      gap: 32px;

      > .separater-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 16px;

        > .question {
          p,
          pre {
            color: #1a1a1a;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 145%;
            letter-spacing: -0.18px;
            word-break: keep-all;
            word-wrap: break-word;
            overflow-y: auto;
          }
        }
      }

      > .separater-right {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        gap: 32px;

        > p {
          color: $grey900;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 28px; /* 183.333% */
          letter-spacing: -1px;
        }

        > .label {
          color: $grey500;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          letter-spacing: -0.16px;
          line-height: 145%; /* 137.5% */
        }

        > .answer {
          > ul.answer-list {
            display: flex;
            flex-direction: column;
            gap: 10px;

            > li.answer-list-item {
              cursor: pointer;
              padding: 16px;
              border: 1px solid $grey100;
              border-radius: 4px;

              > .container {
                display: flex;
                align-items: center;
                padding: 0 !important;
                border-radius: 4px;

                .radio-button {
                  padding: 0;
                  padding-right: 16px;
                }

                > p {
                  color: $grey900;
                  word-break: keep-all;
                  word-wrap: break-word;
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 100%; /* 14px */
                }
                .MuiMenuItem-root {
                  padding: 0 !important;
                }
                > .hasImage {
                  width: 100%;
                  text-align: center;
                  > img {
                    max-width: 100%;
                    max-height: 160px;
                  }
                }
              }

              &.selected {
                background-color: $grey900;

                > .container {
                  > p {
                    color: $white;
                  }
                  > .radio-button {
                    svg {
                      color: $white;
                      > path {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.pages-protected-evaluation-objectives-common-listening {
        .separater-left {
          display: flex;
          flex-direction: column;
          gap: 32px;

          .question-image {
            margin-bottom: 8px;
            text-align: center;

            img {
              max-width: auto;
              max-height: 240px;
            }
          }

          .button-wrapper {
            display: flex;

            button {
              display: inline-block;
              vertical-align: middle;
              padding: 20px;
              border-radius: 32px;
              font-weight: 500;
              height: 52px;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 75%; /* 12px */
              letter-spacing: -0.32px;
            }

            .information {
              color: $grey600;
            }
          }
        }

        .answer {
          .question-title {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 145%; /* 183.333% */
            margin-bottom: 16px;
          }
        }
      }
    }

    > button.submit {
      width: 460px;
      height: 56px;
      border-radius: 32px;
    }
  }
}

// demo done page
.demo-pages-protected-evaluation-done {
  .container {
    display: flex;
    gap: 36px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    z-index: 10000;
    height: 100vh;
    background: $black;
    top: 0;
    color: white;
    text-align: center;
    padding: 0 24px;

    > :nth-child(1) {
      max-width: 180px;

      svg {
        width: 100%;
      }
    }

    > :nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 12px;

      > :first-child {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 34.5px;
        letter-spacing: -0.24px;
      }
      > :nth-child(2) {
        color: $grey600;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: -0.14px;
      }
    }
  }
}

// 언어 선택 페이지
.demo-pages-protected-evaluation-language-test {
  padding: 0 !important;
  background-color: $grey150;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 24px;
    gap: 40px;
    width: 100%;
    max-width: 400px;

    > .container-inner {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px;
      gap: 16px;
      width: 100%;

      > .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        gap: 16px;
        width: 100%;

        > .title-main {
          color: $grey900;
          font-size: 28px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%; /* 37px */
          letter-spacing: -3px;
        }

        > .title-sub {
          p {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 133.333% */
            letter-spacing: -0.4px;
          }
        }
      }

      > .select-container {
        width: 100%;

        .select-wrapper {
          fieldset {
            width: 100%;
          }

          .check-button {
            padding: 16px;
            margin: 0;
            border: 1px solid $grey200;
            transition: all 0.1s;
            font-size: 16px;
            font-weight: 500;
            border-radius: 4px;
            overflow: hidden;
            align-items: center;
            background-color: $white;

            .MuiCheckbox-root {
              padding: 0;
              padding-right: 16px;
            }

            &.selected {
              border: 1px solid $grey300;
              background-color: $grey200;

              .Mui-checked {
                .MuiSvgIcon-root {
                  fill: $grey800 !important;
                }
              }

              .Mui-disabled {
                .MuiSvgIcon-root {
                  fill: $grey800 !important;
                }
              }

              &:hover {
                border-color: $grey300;
              }
            }

            &:not(:last-child) {
              margin-bottom: 10px;
            }
            &:hover {
              border-color: $grey500;
            }
          }
        }
      }

      .button-container {
        display: flex;
        width: 100%;
        gap: 20px;

        > button.start {
          flex: 1;
          display: inline-block;
          height: 52px;
          width: 100%;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 100%;
          letter-spacing: -0.4px;
          border-radius: 50px;
        }
      }
    }
  }
}

// 파입 업로드 테스트
.demo-pages-protected-evaluation-subjectives-file-upload {
  background-color: #f3f3f3;

  > .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 24px;
    gap: 30px;
    max-width: 400px;
    width: 100%;
    border-radius: 8px;

    &.more-width {
      width: 100%;
    }

    > .file-upload-title-box {
      h3 {
        color: $grey900;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.56px;
      }
    }

    > .container-inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px;
      gap: 40px;
      width: 100%;

      > .separater {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 40px;

        > .separater-left {
          flex: 1;
          width: 100%;
          overflow-y: auto;
          padding-right: 20px;

          > .question {
            // font-size: 18px;
            // font-weight: bold;
            // line-height: 23.2px;
            word-break: keep-all;
            word-wrap: break-word;
            margin-bottom: 8px;
            overflow-y: auto;
            // background-color: $grey50;
            // padding: 20px;

            > * {
              all: revert;
              img {
                margin-top: 16px;
                max-width: 100%;
                height: auto;
                margin-top: 16px;
                width: 100%;
              }
            }

            > pre {
              white-space: pre-wrap;
              margin: 0;
              margin-bottom: 8px;
              line-height: 1.8 !important;
              // text-indent: 10px;

              * {
                all: revert;
                margin: 0 !important;
              }
            }

            > .timer {
              // font-size: 18px;
              margin-bottom: 36px;

              &::before {
                content: url('/assets/images/icon/timer.svg');
                margin-right: 7px;
                position: relative;
                top: 4px;
              }
            }

            > .video-box {
              margin-bottom: 32px;
              .question-video {
                display: block;
                width: 100%;
              }
            }
          }
        }

        > .separater-right {
          flex: 1;
          width: 100%;
          overflow-y: auto;
          padding-right: 20px;

          > .answer {
            width: 100%;
            height: 200px;
            border-radius: 16px;
            background-color: $grey100;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              background-color: rgba($grey100, 0.6);
            }

            &:active {
              background-color: rgba($grey100, 0.8);
            }

            > .file-upload-icon {
              margin-bottom: 16px;
            }

            > .file-upload-name {
              margin-top: 8px;
            }
          }
        }
      }

      > button.submit {
        width: 460px;
        height: 56px;
        border-radius: 32px;
      }
    }
  }
}
