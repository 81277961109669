@import '../include.scss';

.components-header-auth-header {
  > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background-color: white;

    > .logo {
      width: 160px;
      height: 64px;
      align-items: center;
      text-align: center;
      display: flex;
      // background-image: url('/assets/images/logo/main_logo.svg');
      > img {
        max-width: 160px;
        max-height: 64px;
        // width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}

.components-header-app-header {
  > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    background-color: white;
    box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.08);

    > .logo {
      width: 160px;
      height: 64px;
      align-items: center;
      text-align: center;
      display: flex;
      // background-image: url('/assets/images/logo/main_logo.svg');
      > img {
        max-width: 160px;
        max-height: 64px;
        // width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
  }
}

.components-header-testtaker-header {
  width: 100%;
  z-index: 100;
  position: sticky;
  top: 0;
  left: 0;

  > .container {
    width: 100%;
    background: $white;

    > .logo {
      width: 160px;
      height: 56px;
      align-items: center;
      justify-content: center;
      width: 100%;
      text-align: center;
      display: flex;
      // background-image: url('/assets/images/logo/main_logo.svg');

      > img {
        max-width: 160px;
        max-height: 64px;
        // width: 100%;
        height: auto;
        margin: 0 auto;
      }
    }
    > .right {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      gap: 10px;
      height: 56px;
      width: 100%;
      background: $white;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.08);

      > .progress {
        width: 100%;
        padding: 0px;
        gap: 10px;

        > .progress-timer {
        }

        > .progress-count {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px;
          gap: 2px;

          > .progress-count-time {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            &::before {
              content: '';
              width: 18px;
              height: 18px;
              background: url('/assets/images/icon/testtaker/demo-timer.svg');
              background-size: 18px;
              background-repeat: no-repeat;
            }

            > span {
              width: 60px;
              text-align: center;
              font-weight: bold;
            }

            > .progress-bar {
              position: relative;
              width: calc(100% - 18px - 60px);
              height: 8px;
              border-radius: 4px;
              background-color: $grey100;

              > .progress-bar-fill {
                height: 100%;
                background-color: $grey900;
                border-radius: 4px;
                transition: width 1s linear;
              }
            }
          }

          > .progress-count-cnt {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            &::before {
              content: '';
              background: url('/assets/images/icon/testtaker/demo-count.svg');
              background-size: 18px;
              background-repeat: no-repeat;
              width: 18px;
              height: 18px;
              display: inline-block;
            }

            > span {
              text-align: center;
              width: 60px;
              // font-size: 16px;
              font-weight: bold;
            }

            > .progress-bar {
              position: relative;
              width: calc(100% - 18px - 60px);
              height: 8px;
              border-radius: 4px;
              background-color: $grey100;

              > .progress-bar-fill {
                height: 100%;
                background-color: $grey900;
                border-radius: 4px;
                transition: width 1s;
              }
            }
          }
        }
      }
      > button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 16px 12px;
        height: 40px;
        border-radius: 32px;
        color: $white;
        word-break: keep-all;
        font-weight: 700;

        &.false {
          cursor: auto;
          background: $grey300;
        }
        &.active {
          cursor: pointer;
          background-color: $grey900;
          color: white;

          &:hover {
            background-color: rgba($grey900, 0.6);
          }

          &:active {
            background-color: rgba($grey900, 0.8);
          }
        }

        &::after {
          content: '';
          background: url('/assets/images/icon/arrow-right-white.svg');
          display: inline-block;
          width: 18px;
          height: 18px;
          background-position: center;
          background-repeat: no-repeat;
          margin-left: 6px;
          position: relative;
          top: 0;
        }
      }
    }
  }
}
